import React, { useEffect, useState, useRef } from "react";
import Navbar from "../dashboard/navbar";
import { toast } from "react-toastify";
import Spinner from "../Spinner";
import "../Admin.css";
import MainTopNav from "../maintopnav";
import Modal from "./Modal";
import { FiSearch } from "react-icons/fi";
import SessionExpiredModal from "../401";
import Moment from "react-moment";
function Transactions() {
    const fromref = useRef();

    const searchref = useRef();
    const toref = useRef();
    const token = JSON.parse(sessionStorage.getItem("token"));
    const [spinner, setSpinner] = useState(true);
    const [WalletTransactions, setWalletTransactions] = useState([]);
    const [prevOffset, setPrevOffset] = useState();
    const [currentOffset, setCurrentOffset] = useState();
    // const [modal, setmodal] = useState(false);
    // const [key, setkey] = useState("");
    const [offset, setoffset] = useState();
    const [prevdisable, setprevdisable] = useState(false);
    const [nextdisable, setnextdisble] = useState(false);
    // const [searchload, setSearchload] = useState(true);
    const [sessionExpired, setSessionExpired] = useState(false);
    let offsetState;
    useEffect(() => {
        offsetState = true;
    }, []);
    const gettransaction = (data) => {
        fetch(
            `${process.env.REACT_APP_BASE_URL}/admin/pending-razorpay-payments?Offset=${data}
            `,
            {
                method: "GET",
                headers: {
                    "content-type": "application/json;charset =UTF-8",
                    Authorization: `token ${token}`,
                },
            }
        )
            .then((Response) => Response.json())
            .then((response) => {
                console.log(response);
                if (response.Status === 1) {
                    setWalletTransactions(response.WalletTransactions);
                    setCurrentOffset(response.Offset);
                    if (offset == response.Offset) {
                        setprevdisable(true);
                    }
                    if (response.Offset == -1) {
                        setnextdisble(true);
                    }
                    if (offsetState) {
                        setoffset(response.Offset);
                        offsetState = false;
                        setprevdisable(true);
                    }
                }
                if (response.Status === 0) {
                    if (response.Message === "Invalid Token") {
                        setSessionExpired(true);
                        return;
                    }
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
                setSpinner(false);
            })
            .catch((err) => {
                setSpinner(false);
                toast.error(
                    `something went Wrong please try again after sometime`,
                    {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    }
                );
            });
    };
    useEffect(() => {
        gettransaction(0);
    }, [token]);
    // const transactionsHandler = () => {
    //     // fromDate: `${current.getFullYear()}-${current.getMonth()}-${current.getDay()}`,
    //     //     toDate: `${to.getFullYear()}-${to.getMonth()}-${to.getDay()}`,
    //     const current = new Date(fromref.current.value);
    //     const to = new Date(toref.current.value);
    //     gettransaction({
    //         offset: 0,
    //         fromDate: current,
    //         toDate: to,
    //     });
    // };
    const prevHandler = () => {
        const current = new Date(fromref.current.value);
        const to = new Date(toref.current.value);
        if (prevOffset === 0) {
            return;
        }
        setnextdisble(false);
        gettransaction(prevOffset - offset);

        setPrevOffset(prevOffset - offset);
        window.scrollTo({ top: 0, behavior: "smooth" });
    };
    const nexthandler = () => {
        const current = new Date(fromref.current.value);
        const to = new Date(toref.current.value);
        if (currentOffset !== -1) {
            setprevdisable(false);
            setPrevOffset(currentOffset);

            gettransaction(currentOffset);
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
    };
    // const modalhandler = (e) => {
    //     setkey(e.currentTarget.getAttribute("id"));
    //     setmodal(true);
    // };
    const searchHandler = (e) => {
        setSpinner(true);
        fetch(
            `${process.env.REACT_APP_BASE_URL}/admin/wallet-transactions/${searchref.current.value}
`,
            {
                headers: {
                    "content-type": "application/json;charset =UTF-8",
                    Authorization: `token ${token}`,
                },
            }
        )
            .then((Response) => Response.json())
            .then((response) => {
                console.log(response);
                if (response.Status === 1) {
                    setprevdisable(true);
                    setnextdisble(true);
                    if (response?.walletTransaction?.razorpay_TransactionId) {
                        setWalletTransactions([response.walletTransaction]);
                    } else {
                        setWalletTransactions([]);
                    }
                }
                setSpinner(false);
            })
            .catch((err) => {
                setSpinner(false);
                toast.error(
                    `something went Wrong please try again after sometime`,
                    {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    }
                );
            });
    };
    const ClearHandler = () => {
        offsetState = true;
        setprevdisable(false);
        setnextdisble(false);
        gettransaction(0);
        searchref.current.value = "";
    };
    const paidHandler = (e) => {
        e.preventDefault();
        let userId = e.currentTarget.getAttribute("userId");
        let razorpayOrderId = e.currentTarget.getAttribute("razorpayOrderId");
        let body = {
            razorpayOrderId: razorpayOrderId,
            userId: userId,
        };
        fetch(
            `${process.env.REACT_APP_BASE_URL}/admin/add-razorpay-payment
            `,
            {
                method: "POST",
                body: JSON.stringify(body),
                headers: {
                    "content-type": "application/json;charset =UTF-8",
                    Authorization: `token ${token}`,
                },
            }
        )
            .then((Response) => Response.json())
            .then((response) => {
                console.log(response);
                if (response.Status === 1) {
                    gettransaction(0);
                }
                if (response.Status === 0) {
                    if (response.Message === "Invalid Token") {
                        setSessionExpired(true);
                        return;
                    }
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
                setSpinner(false);
            })
            .catch((err) => {
                setSpinner(false);
                toast.error(
                    `something went Wrong please try again after sometime`,
                    {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    }
                );
            });
    };
    return (
        <>
            {sessionExpired && <SessionExpiredModal />}
            {/* {modal && <Modal setmodal={setmodal} id={key} />} */}
            <div className="ui">
                <div className="ui-leftside">
                    <div className="navbar">
                        <Navbar active="PendingTransactions" />
                    </div>
                </div>
                <div className="ui-rightside ">
                    <MainTopNav />
                    <div className="ui-wrapper">
                        <div className="Table" style={{ overflowX: "auto" }}>
                            {/* <div className="filter-wrapper">
                                <div className="search-data">
                                    <input
                                        type={"text"}
                                        placeholder="Search by razorypay ID"
                                        ref={searchref}
                                    />
                                    <FiSearch
                                        className="search-icon"
                                        size={23}
                                        color="#6865f0"
                                    />
                                    <button onClick={searchHandler}>
                                        Search
                                    </button>
                                    <button
                                        onClick={ClearHandler}
                                        style={{ background: "red" }}>
                                        Clear
                                    </button>
                                </div>
                                <div className="dateinputs">
                                    <input type={"date"} ref={fromref} />
                                    <input
                                        type={"date"}
                                        ref={toref}
                                        max={
                                            new Date()
                                                .toISOString()
                                                .split("T")[0]
                                        }
                                    />
                                    <button onClick={transactionsHandler}>
                                        Go
                                    </button>
                                </div>
                            </div> */}
                            {spinner ? (
                                <Spinner />
                            ) : (
                                <table>
                                    <thead>
                                        <tr>
                                            <td>User ID</td>
                                            <td>Order ID</td>
                                            <td>razorpay orderId</td>
                                            <td>PGType</td>
                                            <td>Amount</td>
                                            <td>Date</td>
                                            <td>Action</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {WalletTransactions?.length > 0 ? (
                                            WalletTransactions.map((item) => {
                                                return (
                                                    <tr
                                                        className="transactions-row "
                                                        key={
                                                            item.wallet_transactionId
                                                        }
                                                        id={
                                                            item.wallet_transactionId
                                                        }>
                                                        <td>{item.userId}</td>

                                                        <td>
                                                            {
                                                                item.orderReceiptId
                                                            }
                                                        </td>
                                                        <td>
                                                            {" "}
                                                            {
                                                                item.razorpay_orderId
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                item.paymentgateway
                                                            }
                                                        </td>
                                                        <td>
                                                            ₹{item?.orderAmount}
                                                        </td>

                                                        <td>
                                                            <Moment
                                                                locale="de"
                                                                format="DD-MM-YYYY hh:mm a">
                                                                {item.createdAt}
                                                            </Moment>
                                                        </td>
                                                        <td>
                                                            <button
                                                                className="paid-button"
                                                                razorpayOrderId={
                                                                    item.razorpay_orderId
                                                                }
                                                                userId={
                                                                    item.userId
                                                                }
                                                                onClick={
                                                                    paidHandler
                                                                }>
                                                                Mark as Paid
                                                            </button>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td
                                                    colSpan={5}
                                                    style={{
                                                        fontWeight: 500,
                                                        textAlign: "center",
                                                    }}>
                                                    No Transactions Found
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td
                                                colSpan={6}
                                                style={{
                                                    textAlign: "right",
                                                }}>
                                                <button
                                                    className="controlButtons"
                                                    onClick={prevHandler}
                                                    disabled={prevdisable}>
                                                    Previous
                                                </button>
                                                <button
                                                    onClick={nexthandler}
                                                    className="controlButtons"
                                                    disabled={nextdisable}
                                                    style={{
                                                        marginLeft: "1rem",
                                                    }}>
                                                    Next
                                                </button>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Transactions;

import React, { useEffect, useState, useRef } from "react";
import Navbar from "../dashboard/navbar";
import { toast } from "react-toastify";
import Spinner from "../Spinner";
import TopNav from "./topNav";
import "../Admin.css";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "../transactions/Modal";
import MainTopNav from "../maintopnav";
import { IoMdArrowRoundBack } from "react-icons/io";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WalletDebit from "../businessUsers/walletDebit";
import ExpiredModal from "../401";
function Transactions() {
    const { id } = useParams();
    const Navigate = useNavigate();
    const fromref = useRef();
    const toref = useRef();
    const [modal, setmodal] = useState(false);
    const [key, setkey] = useState("");
    const token = JSON.parse(sessionStorage.getItem("token"));
    const [spinner, setSpinner] = useState(true);
    const [WalletTransactions, setWalletTransactions] = useState([]);
    const [firstName, setFirstName] = useState();

    const [sessionExpired, setSessionExpired] = useState(false);
    const [debitModal, setDebitModal] = useState(false);
    const dateTransactions = () => {
        const fromDate = fromref.current.value;
        const toDate = toref.current.value;
        const current = new Date(fromDate);
        const to = new Date(toDate);
        setSpinner(true);
        console.log(current);
        let header = {
            headers: {
                "content-type": "application/json;charset =UTF-8",
                Authorization: `token ${token}`,
            },
        };
        if (fromDate && toDate) {
            header = {
                ...header,
                method: "POST",
                body: JSON.stringify({
                    fromDate: `${current.getFullYear()}-${current.getMonth() +
                        1}-${current.getDate()}`,
                    toDate: `${to.getFullYear()}-${to.getMonth() +
                        1}-${to.getDate()}`,
                }),
            };
        }
        fetch(
            `${process.env.REACT_APP_BASE_URL}/admin/${id}/wallet-transactions?Offset=0`,
            { ...header }
        )
            .then((Response) => Response.json())
            .then((response) => {
                console.log(response);
                if (response.Status === 1) {
                    setWalletTransactions(response.WalletTransactions);
                }
                if (response.Status === 0) {
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
                setSpinner(false);
            })
            .catch((err) => {
                setSpinner(false);
                toast.error(
                    `something went Wrong please try again after sometime`,
                    {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    }
                );
            });
    };
    useEffect(() => {
        setFirstName(sessionStorage.getItem("firstname"));
        dateTransactions();
    }, [token, id]);
    const transactionsHandler = () => {
        const fromDate = fromref.current.value;
        const toDate = toref.current.value;
        if (!fromDate) {
            alert("Please Enter from Date");
        } else if (!toDate) {
            alert("Please enter To Date");
        } else {
            dateTransactions();
        }
    };
    const modalhandler = (e) => {
        setkey(e.currentTarget.getAttribute("id"));
        setmodal(true);
    };
    const backHandler = () => {
        Navigate("/userlist");
    };
    const isKYCverified = sessionStorage.getItem("isKYCverified");
    // const refreshHandler = () => {

    // };
    return (
        <>
            {sessionExpired && <Modal />}
            {debitModal && (
                <WalletDebit
                    setmodal={setDebitModal}
                    dateTransactions={dateTransactions}
                    setspinner={setSpinner}
                />
            )}
            {modal && <Modal setmodal={setmodal} id={key} />}
            <div className="ui">
                <div className="ui-leftside">
                    <div className="navbar">
                        <Navbar active="Userlist" />
                    </div>
                </div>
                <div className="ui-rightside ">
                    <MainTopNav />
                    <div className="ui-wrapper">
                        <div className="User-name">
                            <button
                                className="back-button"
                                onClick={backHandler}>
                                <IoMdArrowRoundBack />
                                <p>Back</p>
                            </button>
                            <p
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "0.5rem",
                                }}>
                                {firstName}{" "}
                                {isKYCverified === "1" && (
                                    <CheckCircleIcon
                                        style={{ color: "green" }}
                                    />
                                )}
                            </p>
                        </div>
                        <div className="topNav">
                            <TopNav active="Transactions" />
                        </div>
                        <div className="Table" style={{ overflowX: "auto" }}>
                            <div className="dateinputs">
                                <input type={"date"} ref={fromref} />
                                <input
                                    type={"date"}
                                    ref={toref}
                                    max={new Date().toISOString().split("T")[0]}
                                />
                                <button onClick={transactionsHandler}>
                                    Go
                                </button>
                                <button
                                    onClick={() => {
                                        dateTransactions();
                                    }}>
                                    Refresh
                                </button>
                                <button
                                    onClick={() => setDebitModal(true)}
                                    style={{
                                        borderRadius: "10px",
                                        backgroundColor: "red",
                                    }}>
                                    Wallet Debit
                                </button>
                            </div>
                            {spinner ? (
                                <Spinner />
                            ) : (
                                <table>
                                    <thead>
                                        <tr>
                                            <td>Date</td>
                                            <td>Order ID</td>
                                            <td>Type</td>
                                            <td>PGType</td>
                                            <td>WithDrawal</td>
                                            <td>Deposits</td>
                                            <td>Status</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {WalletTransactions?.length > 0 ? (
                                            WalletTransactions.map((item) => {
                                                const datestring = String(
                                                    item.transactionDateTime
                                                ).split("T");
                                                const date = new Date(
                                                    datestring[0]
                                                );
                                                const month = date.toLocaleString(
                                                    "default",
                                                    { month: "short" }
                                                );
                                                const time = datestring[1].split(
                                                    ":"
                                                );
                                                return (
                                                    <tr
                                                        className="transactions-row "
                                                        key={
                                                            item.wallet_transactionId
                                                        }
                                                        id={
                                                            item.wallet_transactionId
                                                        }
                                                        onClick={modalhandler}>
                                                        <td>
                                                            {" "}
                                                            {String(
                                                                date.getDate()
                                                            ).padStart(
                                                                2,
                                                                "0"
                                                            )}{" "}
                                                            {month}{" "}
                                                            {date.getFullYear()}{" "}
                                                            {`${time[0]}:${time[1]}`}
                                                        </td>
                                                        <td>
                                                            {
                                                                item.wallet_transactionId
                                                            }
                                                        </td>
                                                        <td>
                                                            {" "}
                                                            {item?.transactionType ===
                                                                1 && (
                                                                <span>
                                                                    Recharge
                                                                </span>
                                                            )}
                                                            {item?.transactionType ===
                                                                2 && (
                                                                <span>
                                                                    Payout
                                                                </span>
                                                            )}
                                                            {item?.transactionType ===
                                                                3 && (
                                                                <span>
                                                                    Wallet Debit
                                                                </span>
                                                            )}
                                                            {item?.transactionType ===
                                                                4 && (
                                                                <span>
                                                                    Wallet
                                                                    Credit
                                                                </span>
                                                            )}
                                                            {item?.transactionType ===
                                                                5 && (
                                                                <span>
                                                                    payout
                                                                    refund
                                                                </span>
                                                            )}
                                                            {item?.transactionType ===
                                                                6 && (
                                                                <span>
                                                                    payout
                                                                    charges
                                                                </span>
                                                            )}
                                                            {item?.transactionType ===
                                                                7 && (
                                                                <span>
                                                                    wallet
                                                                    commission{" "}
                                                                    <br />
                                                                    for referred
                                                                    users
                                                                </span>
                                                            )}
                                                            {item?.transactionType ===
                                                                8 && (
                                                                <span>
                                                                    BBPS
                                                                    Transactions
                                                                </span>
                                                            )}
                                                            {item?.transactionType ===
                                                                9 && (
                                                                <span>
                                                                    Admin Debit
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td>
                                                            {
                                                                item.paymentgateway
                                                            }
                                                        </td>
                                                        <td>
                                                            {" "}
                                                            {(item?.transactionType ===
                                                                2 ||
                                                                item?.transactionType ===
                                                                    3 ||
                                                                item?.transactionType ===
                                                                    6 ||
                                                                item?.transactionType ===
                                                                    9) && (
                                                                <span
                                                                    style={{
                                                                        color:
                                                                            "#F0532D",
                                                                        fontWeight: 500,
                                                                    }}>
                                                                    ₹
                                                                    {
                                                                        item?.amount
                                                                    }
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td>
                                                            {(item?.transactionType ===
                                                                1 ||
                                                                item?.transactionType ===
                                                                    4 ||
                                                                item?.transactionType ===
                                                                    5 ||
                                                                item?.transactionType ===
                                                                    7) && (
                                                                <span
                                                                    style={{
                                                                        color:
                                                                            "#0DB14B",
                                                                        fontWeight: 500,
                                                                    }}>
                                                                    ₹
                                                                    {
                                                                        item?.amount
                                                                    }
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td>
                                                            {" "}
                                                            {item?.transactionStatus ===
                                                                2 && (
                                                                <span className="pending-span">
                                                                    {" "}
                                                                    Pending
                                                                </span>
                                                            )}
                                                            {item?.transactionStatus ===
                                                                1 && (
                                                                <span className="completed-span">
                                                                    {" "}
                                                                    Processed
                                                                </span>
                                                            )}
                                                            {item?.transactionStatus ===
                                                                3 && (
                                                                <span className="failed-span">
                                                                    {" "}
                                                                    Failed
                                                                </span>
                                                            )}
                                                            {item?.transactionStatus ===
                                                                4 && (
                                                                <span className="reversed-span">
                                                                    {" "}
                                                                    Reversed
                                                                </span>
                                                            )}
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td
                                                    colSpan={5}
                                                    style={{
                                                        fontWeight: 500,
                                                        textAlign: "center",
                                                    }}>
                                                    No transactions Found
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Transactions;

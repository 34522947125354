import React from "react";
import loginleft from "../Assets/images/login/loginleft.svg";
import "../Components/Admin.css";
import Profile from "../Assets/images/login/Profile.svg";
import Lock from "../Assets/images/login/Lock.svg";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
function Login() {
    const Navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const onSubmit = (data) => {
        fetch(`${process.env.REACT_APP_BASE_URL}/admin/login`, {
            method: "POST",
            body: JSON.stringify({
                ...data,
            }),
            headers: { "content-type": "application/json;charset =UTF-8" },
        })
            .then((Response) => Response.json())
            .then((response) => {
                console.log(response);
                if (response.Status === 1) {
                    sessionStorage.setItem(
                        "token",
                        JSON.stringify(response.Token)
                    );
                    sessionStorage.setItem(
                        "userDetails",
                        JSON.stringify(response.adminDetails)
                    );
                    Navigate("/dashboard");
                }
                if (response.Status === 0) {
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                toast.error(`${err}`, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });
            });
    };
    return (
        <div className="Login">
            <img src={loginleft} alt="" />
            <div className="login-form">
                <div className="header">
                    <h3>
                        Welcome to <span>Payville</span>
                    </h3>
                    <p>Please log in to continue..</p>
                </div>
                <div className="wrapper">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-control">
                            <input
                                type="email"
                                name="EmailID"
                                placeholder="Email"
                                {...register("emailId", {
                                    required: "Enter EmailID",
                                })}
                            />

                            <img src={Profile} alt="" />

                            {errors.emailId && (
                                <p
                                    style={{
                                        color: "red",
                                        marginTop: "0.3rem",
                                    }}>
                                    {errors.emailId.message}
                                </p>
                            )}
                        </div>

                        <div className="form-control">
                            <input
                                name="Password"
                                type={"password"}
                                placeholder="Password"
                                {...register("password", {
                                    required: "Enter password",
                                })}
                            />
                            <img src={Lock} alt="" />
                            {errors.password && (
                                <p
                                    style={{
                                        color: "red",
                                        marginTop: "0.3rem",
                                    }}>
                                    {errors.password.message}
                                </p>
                            )}
                        </div>

                        <button type="submit">Log in</button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Login;

import React, { useEffect, useState, useRef } from "react";
import Navbar from "../dashboard/navbar";
import { toast } from "react-toastify";
import Spinner from "../Spinner";
import MainTopNav from "../maintopnav";
import { useNavigate } from "react-router-dom";
import { FiSearch } from "react-icons/fi";

import Modal from "../401";
function Userlist() {
    const searchref = useRef();
    const Navigate = useNavigate();
    const token = JSON.parse(sessionStorage.getItem("token"));

    const [sessionExpired, setSessionExpired] = useState(false);
    const [pagination, setpagination] = useState(true);
    const [offset, setoffset] = useState();
    const [spinner, setSpinner] = useState(true);
    const [userList, setUserList] = useState([]);
    const [prevOffset, setPrevOffset] = useState();
    const [currentOffset, setCurrentOffset] = useState();
    const [prevdisable, setprevdisable] = useState(false);
    const [nextdisable, setnextdisble] = useState(false);
    const [option, setOption] = useState();
    let offsetState;
    useEffect(() => {
        offsetState = true;
    }, []);

    const getuserlist = (offset) => {
        fetch(
            `${process.env.REACT_APP_BASE_URL}/admin/business/all?offset=${offset}`,
            {
                headers: {
                    "content-type": "application/json;charset =UTF-8",
                    Authorization: `token ${token}`,
                },
            }
        )
            .then((Response) => Response.json())
            .then((response) => {
                console.log(response);
                if (response.Status === 1) {
                    setpagination(true);
                    setUserList(response.users);
                    setCurrentOffset(response.offset);
                    if (offset == response.offset) {
                        setprevdisable(true);
                    }
                    if (response.offset == -1) {
                        setnextdisble(true);
                    }
                    if (offsetState) {
                        setoffset(response.offset);
                        offsetState = false;
                        setprevdisable(true);
                    }
                }
                if (response.Status === 0) {
                    if (response.Message === "Invalid Token") {
                        setSessionExpired(true);
                        return;
                    }
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
                setSpinner(false);
            })
            .catch((err) => {
                setSpinner(false);
                toast.error(
                    `something went Wrong please try again after sometime`,
                    {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    }
                );
            });
    };
    useEffect(() => {
        getuserlist(0);
    }, [token]);
    const userdetailsHandler = (e) => {
        sessionStorage.setItem(
            "firstname",
            e.currentTarget.getAttribute("companyname")
        );
        sessionStorage.setItem(
            "businessuser",
            e.currentTarget.getAttribute("userid")
        );
        Navigate(
            `/businessUser/userdetails/${e.currentTarget.getAttribute(
                "userid"
            )}`
        );
    };
    const prevHandler = () => {
        if (prevOffset === 0) {
            return;
        }
        setnextdisble(false);
        getuserlist(prevOffset - offset);

        setPrevOffset(prevOffset - offset);
        window.scrollTo({ top: 0, behavior: "smooth" });
    };
    const nexthandler = () => {
        if (currentOffset !== -1) {
            setprevdisable(false);
            setPrevOffset(currentOffset);
            getuserlist(currentOffset);
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
    };
    const search = (data) => {
        fetch(`${process.env.REACT_APP_BASE_URL}/admin/search-users`, {
            method: "POST",
            body: JSON.stringify({
                ...data,
                userType: "BUSINESS",
            }),
            headers: {
                "content-type": "application/json;charset =UTF-8",
                Authorization: `token ${token}`,
            },
        })
            .then((Response) => Response.json())
            .then((response) => {
                console.log(response);
                if (response.Status === 1) {
                    setUserList(response.Users);
                }
                if (response.Status === 0) {
                    if (response.Message === "Invalid Token") {
                        setSessionExpired(true);
                        return;
                    }
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                toast.error(
                    `something went Wrong please try again after sometime`,
                    {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    }
                );
            });
    };
    const searchHandler = (e) => {
        console.log(e.target.value);
        if (searchref.current.value.length === 0) {
            getuserlist(0);
        } else if (isNaN(searchref.current.value)) {
            search({ name: searchref.current.value });
            setpagination(false);
        } else {
            setpagination(false);
            search({ mobileNumber: searchref.current.value });
        }
    };

    const filterSearch = (Url) => {
        console.log(Url);
        fetch(Url, {
            headers: {
                "content-type": "application/json;charset =UTF-8",
                Authorization: `token ${token}`,
            },
        })
            .then((Response) => Response.json())
            .then((response) => {
                console.log(response);
                if (response.Status === 1) {
                    setUserList(response.Users);
                }
                if (response.Status === 0) {
                    if (response.Message === "Invalid Token") {
                        setSessionExpired(true);
                        return;
                    }
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                toast.error(
                    `something went Wrong please try again after sometime`,
                    {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    }
                );
            });
    };
    const FilterHandler = (e) => {
        if (option === "1") {
            filterSearch(`${process.env.REACT_APP_BASE_URL}/admin/search-users-filters?userType=BUSINESS&isKYCverified=1
            `);
        } else if (option === "2") {
            filterSearch(`${process.env.REACT_APP_BASE_URL}/admin/search-users-filters?userType=BUSINESS&isKYCverified=0
            `);
        } else if (option === "3") {
            filterSearch(`${process.env.REACT_APP_BASE_URL}/admin/search-users-filters?userType=BUSINESS&Last7DaysTransactions=1

            `);
        } else if (option === "4") {
            filterSearch(`${process.env.REACT_APP_BASE_URL}/admin/search-users-filters?userType=BUSINESS&Last7DaysTransactions=0

            `);
        } else {
            alert("please select filter");
        }
    };
    const selectHandler = (e) => {
        setOption(e.target.value);
    };
    const exportHandler = () => {
        fetch(`${process.env.REACT_APP_BASE_URL}/admin/business/all?type=csv`, {
            headers: {
                "content-type": "application/json;charset =UTF-8",
                Authorization: `token ${token}`,
            },
        })
            .then((Response) => Response.json())
            .then((response) => {
                console.log(response);
                if (response.Status === 1) {
                    //filename

                    fetch(response.filename).then((response) => {
                        response.blob().then((blob) => {
                            // Creating new object of PDF file
                            const fileURL = window.URL.createObjectURL(blob);
                            // Setting various property values
                            let alink = document.createElement("a");
                            alink.href = fileURL;
                            alink.download = `BusinessUsers.csv`;
                            alink.click();
                        });
                    });
                }
                if (response.Status === 0) {
                    if (response.Message === "Invalid Token") {
                        setSessionExpired(true);
                        return;
                    }
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                toast.error(
                    `something went Wrong please try again after sometime`,
                    {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    }
                );
            });
    };
    return (
        <>
            {sessionExpired && <Modal />}
            <div className="ui">
                <div className="ui-leftside">
                    <div className="navbar">
                        <Navbar active="BusinessUsers" />
                    </div>
                </div>
                <div className="ui-rightside ">
                    <MainTopNav />
                    <div className="ui-wrapper">
                        <div className="filter-wrapper">
                            <div className="search-data">
                                <input
                                    type={"text"}
                                    placeholder="Search by Name or Number"
                                    ref={searchref}
                                />
                                <FiSearch
                                    className="search-icon"
                                    size={23}
                                    color="#6865f0"
                                />
                                <button onClick={searchHandler}>Search</button>
                            </div>
                            <div className="filter">
                                <select
                                    defaultValue=""
                                    onChange={selectHandler}>
                                    <option value="">Select Filter</option>
                                    <option value="1">KYC Verified</option>
                                    <option value="2">KYC Not Verified</option>
                                    <option value="3">
                                        Atleast One Transaction in 7 Days
                                    </option>
                                    <option value="4">
                                        No Transactions in Last 7 Days
                                    </option>
                                </select>
                                <button onClick={FilterHandler}>Get</button>
                            </div>
                        </div>
                        <div className="Table" style={{ overflowX: "auto" }}>
                            <div className="business-user-header">
                                <h3>Users</h3>
                                <button onClick={exportHandler}>export</button>
                            </div>
                            {spinner ? (
                                <Spinner />
                            ) : (
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Company Name</th>
                                            <th>Mobile Number</th>
                                            <th>Registration Date</th>
                                            <th>
                                                Wallet Commission
                                                <br /> Percentage
                                            </th>
                                            <th>Wallet Balance</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {userList?.length > 0 ? (
                                            userList.map((item) => {
                                                const datestring = String(
                                                    item.registrationDate
                                                ).split("T");
                                                const date = new Date(
                                                    datestring[0]
                                                );
                                                const month = date.toLocaleString(
                                                    "default",
                                                    { month: "short" }
                                                );
                                                return (
                                                    <tr
                                                        userid={item.userId}
                                                        className="user-details"
                                                        companyname={
                                                            item.companyName
                                                        }
                                                        onClick={
                                                            userdetailsHandler
                                                        }>
                                                        <td
                                                            style={{
                                                                width: "15%",
                                                            }}>
                                                            {item.firstName}
                                                        </td>
                                                        <td>
                                                            {item.companyName}
                                                        </td>
                                                        <td>
                                                            {item.mobileNumber}
                                                        </td>
                                                        <td>
                                                            {String(
                                                                date.getDate()
                                                            ).padStart(
                                                                2,
                                                                "0"
                                                            )}{" "}
                                                            {month}{" "}
                                                            {date.getFullYear()}
                                                        </td>
                                                        <td>
                                                            {
                                                                item.walletCommissionPercentage
                                                            }
                                                            %
                                                        </td>
                                                        <td>
                                                            ₹{" "}
                                                            {item.walletAmount}
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td
                                                    colSpan={5}
                                                    style={{ fontWeight: 500 }}>
                                                    No Referal Found
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                    {pagination && (
                                        <tfoot>
                                            <tr>
                                                <td
                                                    colSpan={6}
                                                    style={{
                                                        textAlign: "right",
                                                    }}>
                                                    <button
                                                        className="controlButtons"
                                                        disabled={prevdisable}
                                                        onClick={prevHandler}>
                                                        Previous
                                                    </button>
                                                    <button
                                                        onClick={nexthandler}
                                                        className="controlButtons"
                                                        disabled={nextdisable}
                                                        style={{
                                                            marginLeft: "1rem",
                                                        }}>
                                                        Next
                                                    </button>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    )}
                                </table>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Userlist;

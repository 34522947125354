import React from "react";
import { NavLink } from "react-router-dom";
function topNav(props) {
    const isactive = props.active;
    const id = sessionStorage.getItem("businessuser");
    return (
        <div className="topNav-Links">
            <NavLink
                className={
                    isactive === "userdetails"
                        ? "topnavactive"
                        : "topnavinactive"
                }
                to={`/businessUser/userdetails/${id}`}>
                <p>User details</p>
            </NavLink>
            <NavLink
                className={
                    isactive === "Dashboard" ? "topnavactive" : "topnavinactive"
                }
                to={`/businessUser/userdetails/${id}/Dashboard`}>
                <p>Dashboard</p>
            </NavLink>
            <NavLink
                className={
                    isactive === "Transactions"
                        ? "topnavactive"
                        : "topnavinactive"
                }
                to={`/businessUser/userdetails/${id}/Transactions`}>
                <p>Transactions</p>
            </NavLink>
            <NavLink
                className={
                    isactive === "Referral" ? "topnavactive" : "topnavinactive"
                }
                to={`/businessUser/userdetails/${id}/Referral`}>
                <p>Referral Users</p>
            </NavLink>
            <NavLink
                className={
                    isactive === "Settings" ? "topnavactive" : "topnavinactive"
                }
                to={`/businessUser/userdetails/${id}/Settings`}>
                <p>Settings</p>
            </NavLink>
            <NavLink
                className={
                    isactive === "info" ? "topnavactive" : "topnavinactive"
                }
                to={`/businessUser/userdetails/${id}/info`}>
                <p>Business Info</p>
            </NavLink>
        </div>
    );
}

export default topNav;

import React, { useEffect, useState } from "react";
import Navbar from "../dashboard/navbar";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import Spinner from "../Spinner";
import Maintopnav from "../maintopnav";

import Modal from "../401";
function Settings() {
    const token = JSON.parse(sessionStorage.getItem("token"));
    const { id } = useParams();
    const [spinner, setSpinner] = useState(true);
    const [details, setDetails] = useState({});
    const [Fileuploaded, setFile] = useState("");
    const [base64, setBse64] = useState("");
    const [sessionExpired, setSessionExpired] = useState(false);
    const [disabled, setDisabled] = useState(false);
    useEffect(() => {
        fetch(`${process.env.REACT_APP_BASE_URL}/admin/settings`, {
            headers: {
                "content-type": "application/json;charset =UTF-8",
                Authorization: `token ${token}`,
            },
        })
            .then((Response) => Response.json())
            .then((response) => {
                console.log(response);
                if (response.Status === 1) {
                    setDetails(response.Settings);
                    setFile(response?.Settings?.banner);
                }
                if (response.Status === 0) {
                    if (response.Message === "Invalid Token") {
                        setSessionExpired(true);
                        return;
                    }
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
                setSpinner(false);
            })
            .catch((err) => {
                setSpinner(false);
                toast.error(
                    `something went Wrong please try again after sometime`,
                    {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    }
                );
            });
    }, [token, id]);
    useEffect(() => {
        if (details.isPG1enabled == 0 && details.isPG2enabled == 0) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [details]);
    const submitHandler = (e) => {
        e.preventDefault();
        console.log(details);
        if (disabled) {
            alert("both payment Gateways cannot be disabled ");
            return;
        }

        setDisabled(false);
        let body = { ...details };
        if (base64) {
            body = { ...details, banner: base64 };
        } else {
            delete body.banner;
        }
        fetch(`${process.env.REACT_APP_BASE_URL}/admin/settings`, {
            method: "PUT",
            body: JSON.stringify({
                ...body,
            }),
            headers: {
                "content-type": "application/json;charset =UTF-8",
                Authorization: `token ${token}`,
            },
        })
            .then((Response) => Response.json())
            .then((response) => {
                if (response.Status === 1) {
                    toast.success(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
                if (response.Status === 0) {
                    if (response.Message === "Invalid Token") {
                        setSessionExpired(true);
                        return;
                    }
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
                setSpinner(false);
            })
            .catch((err) => {
                setSpinner(false);
                toast.error(
                    `something went Wrong please try again after sometime`,
                    {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    }
                );
            });
    };

    const SettingChangeHandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        if (name === "news") {
            setDetails((prev) => {
                return {
                    ...prev,
                    [name]: value,
                };
            });
            return;
        }

        if (name === "isPG1enabled" || name === "isPG2enabled") {
            setDetails((prev) => {
                return {
                    ...prev,
                    [name]: e.target.checked ? 1 : 0,
                };
            });
            return;
        }
        setDetails((prev) => {
            return {
                ...prev,
                [name]: Number(value),
            };
        });
    };
    const fileHandler = (event) => {
        const file = event.target.files[0]; // Get the first selected file
        if (file) {
            const reader = new FileReader();

            // When the file is read
            reader.onload = () => {
                const base64String = reader.result; // Extract Base64
                setBse64(base64String); // Save Base64 string
                setFile(base64String);
            };

            // Read the file as a Data URL
            reader.readAsDataURL(file);
        }
    };
    return (
        <>
            {sessionExpired && <Modal />}
            <div className="ui">
                <div className="ui-leftside">
                    <div className="navbar">
                        <Navbar active="settings" />
                    </div>
                </div>
                <div className="ui-rightside ">
                    <Maintopnav />
                    <div className="ui-wrapper">
                        {spinner ? (
                            <Spinner />
                        ) : (
                            <form className="settings" onSubmit={submitHandler}>
                                <h3
                                    style={{
                                        fontSize: "2rem",
                                        fontWeight: "500",
                                    }}>
                                    Settings
                                </h3>
                                <div className="settting-detail">
                                    <label htmlFor="blockuser">
                                        Deposit Lock
                                    </label>
                                    <div className="radio-button-wrapper">
                                        <div className="radio-buttons">
                                            <input
                                                type={"radio"}
                                                name="rechargeLock"
                                                value={1}
                                                onChange={SettingChangeHandler}
                                                defaultChecked={
                                                    details.rechargeLock === 1
                                                }
                                            />
                                            <label htmlFor="yes">Yes</label>
                                        </div>
                                        <div className="radio-buttons">
                                            <input
                                                type={"radio"}
                                                onChange={SettingChangeHandler}
                                                name="rechargeLock"
                                                value={0}
                                                defaultChecked={
                                                    details.rechargeLock === 0
                                                }
                                            />
                                            <label htmlFor="No">No</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="settting-detail">
                                    <label htmlFor="blockuser">
                                        Payouts Lock
                                    </label>
                                    <div className="radio-button-wrapper">
                                        <div className="radio-buttons">
                                            <input
                                                type={"radio"}
                                                onChange={SettingChangeHandler}
                                                name="payoutLock"
                                                value={1}
                                                defaultChecked={
                                                    details.payoutLock === 1
                                                }
                                            />
                                            <label htmlFor="yes">Yes</label>
                                        </div>
                                        <div className="radio-buttons">
                                            <input
                                                onChange={SettingChangeHandler}
                                                type={"radio"}
                                                name="payoutLock"
                                                value={0}
                                                defaultChecked={
                                                    details.payoutLock === 0
                                                }
                                            />
                                            <label htmlFor="No">No</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="settting-detail">
                                    <label htmlFor="system lock">
                                        Complete System Lock
                                    </label>
                                    <div className="radio-button-wrapper">
                                        <div className="radio-buttons">
                                            <input
                                                type={"radio"}
                                                onChange={SettingChangeHandler}
                                                name="completeSystemLock"
                                                value={1}
                                                defaultChecked={
                                                    details.completeSystemLock ===
                                                    1
                                                }
                                            />
                                            <label htmlFor="yes">Yes</label>
                                        </div>
                                        <div className="radio-buttons">
                                            <input
                                                type={"radio"}
                                                onChange={SettingChangeHandler}
                                                name="completeSystemLock"
                                                value={0}
                                                defaultChecked={
                                                    details.completeSystemLock ===
                                                    0
                                                }
                                            />
                                            <label htmlFor="No">No</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="settting-detail">
                                    <label htmlFor="system lock">
                                        BBPS Visible
                                    </label>
                                    <div className="radio-button-wrapper">
                                        <div className="radio-buttons">
                                            <input
                                                type={"radio"}
                                                onChange={SettingChangeHandler}
                                                name="isBBPSvisible"
                                                value={1}
                                                defaultChecked={
                                                    details.isBBPSvisible === 1
                                                }
                                            />
                                            <label htmlFor="yes">Yes</label>
                                        </div>
                                        <div className="radio-buttons">
                                            <input
                                                type={"radio"}
                                                onChange={SettingChangeHandler}
                                                name="isBBPSvisible"
                                                value={0}
                                                defaultChecked={
                                                    details.isBBPSvisible === 0
                                                }
                                            />
                                            <label htmlFor="No">No</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="settting-detail">
                                    <label htmlFor="">Payment Gateway</label>
                                    <div className="radio-button-wrapper">
                                        <div className="radio-buttons">
                                            <input
                                                type={"checkbox"}
                                                id="pg1"
                                                onChange={SettingChangeHandler}
                                                name="isPG1enabled"
                                                defaultChecked={
                                                    details.isPG1enabled === 1
                                                }
                                            />
                                            <label htmlFor="pg1">PG 1 </label>
                                        </div>
                                        <div className="radio-buttons">
                                            <input
                                                type={"checkbox"}
                                                id={"pg2"}
                                                onChange={SettingChangeHandler}
                                                name="isPG2enabled"
                                                defaultChecked={
                                                    details.isPG2enabled === 1
                                                }
                                            />
                                            <label htmlFor="pg2">PG2</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="settting-detail">
                                    <label htmlFor="isPG1SettlementsEnabled">
                                        isPG1SettlementsEnabled
                                    </label>
                                    <div className="radio-button-wrapper">
                                        <div className="radio-buttons">
                                            <input
                                                type={"radio"}
                                                name="isPG1SettlementsEnabled"
                                                value={1}
                                                onChange={SettingChangeHandler}
                                                defaultChecked={
                                                    details.isPG1SettlementsEnabled ===
                                                    1
                                                }
                                            />
                                            <label htmlFor="yes">Yes</label>
                                        </div>
                                        <div className="radio-buttons">
                                            <input
                                                type={"radio"}
                                                name="isPG1SettlementsEnabled"
                                                onChange={SettingChangeHandler}
                                                value={0}
                                                defaultChecked={
                                                    details.isPG1SettlementsEnabled ===
                                                    0
                                                }
                                            />
                                            <label htmlFor="No">No</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="settting-detail">
                                    <label htmlFor="isPG2SettlementsEnabled">
                                        isPaymentLinksEnabled
                                    </label>
                                    <div className="radio-button-wrapper">
                                        <div className="radio-buttons">
                                            <input
                                                type={"radio"}
                                                name="isPaymentLinksEnabled"
                                                value={1}
                                                onChange={SettingChangeHandler}
                                                defaultChecked={
                                                    details.isPaymentLinksEnabled ===
                                                    1
                                                }
                                            />
                                            <label htmlFor="yes">Yes</label>
                                        </div>
                                        <div className="radio-buttons">
                                            <input
                                                type={"radio"}
                                                name="isPaymentLinksEnabled"
                                                onChange={SettingChangeHandler}
                                                value={0}
                                                defaultChecked={
                                                    details.isPaymentLinksEnabled ===
                                                    0
                                                }
                                            />
                                            <label htmlFor="No">No</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="settting-detail">
                                    <label htmlFor="isPG2SettlementsEnabled">
                                        isUserSignupEnabled
                                    </label>
                                    <div className="radio-button-wrapper">
                                        <div className="radio-buttons">
                                            <input
                                                type={"radio"}
                                                name="isUserSignupEnabled"
                                                value={1}
                                                onChange={SettingChangeHandler}
                                                defaultChecked={
                                                    details.isUserSignupEnabled ===
                                                    1
                                                }
                                            />
                                            <label htmlFor="yes">Yes</label>
                                        </div>
                                        <div className="radio-buttons">
                                            <input
                                                type={"radio"}
                                                name="isUserSignupEnabled"
                                                onChange={SettingChangeHandler}
                                                value={0}
                                                defaultChecked={
                                                    details.isUserSignupEnabled ===
                                                    0
                                                }
                                            />
                                            <label htmlFor="No">No</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="settting-detail">
                                    <label htmlFor="isRazorPayPGAvailable">
                                        Payout Account Switch
                                    </label>
                                    <div className={"radio-button-wrapper"}>
                                        <div className={"radio-buttons"}>
                                            <input
                                                type={"radio"}
                                                name="accountSwitch"
                                                value={0}
                                                onChange={SettingChangeHandler}
                                                defaultChecked={
                                                    details.accountSwitch === 0
                                                }
                                            />
                                            <label htmlFor="yes">
                                                Bank Account{" "}
                                            </label>
                                        </div>
                                        <div className={"radio-buttons"}>
                                            <input
                                                type={"radio"}
                                                onChange={SettingChangeHandler}
                                                name="accountSwitch"
                                                value={1}
                                                defaultChecked={
                                                    details.accountSwitch === 1
                                                }
                                            />
                                            <label htmlFor="No">
                                                Virtual Account
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="settting-detail-percentage">
                                    <label htmlFor="defaultUserPercentage">
                                        default User Percentage
                                    </label>
                                    <input
                                        name="defaultUserPercentage"
                                        defaultValue={
                                            details?.defaultUserPercentage
                                        }
                                        onChange={SettingChangeHandler}></input>
                                </div>

                                <div className="settting-detail-percentage">
                                    <label htmlFor="businessPercentage">
                                        default business Percentage
                                    </label>
                                    <input
                                        name="defaultBusinessPercentage"
                                        defaultValue={
                                            details?.defaultBusinessPercentage
                                        }
                                        onChange={SettingChangeHandler}></input>
                                </div>
                                <div className="settting-detail-percentage">
                                    <label htmlFor="pg2walletCommissionUserPercentage">
                                        pg2 wallet Commission User Percentage
                                    </label>
                                    <input
                                        name="pg2walletCommissionUserPercentage"
                                        defaultValue={
                                            details?.pg2walletCommissionUserPercentage
                                        }
                                        onChange={SettingChangeHandler}></input>
                                </div>
                                <div className="settting-detail-percentage">
                                    <label htmlFor="pg2walletCommissionBusinessPercentage">
                                        pg2 wallet commission Business
                                        percentage
                                    </label>
                                    <input
                                        name="pg2walletCommissionBusinessPercentage"
                                        defaultValue={
                                            details?.pg2walletCommissionBusinessPercentage
                                        }
                                        onChange={SettingChangeHandler}></input>
                                </div>
                                <div className="settting-detail-percentage">
                                    <label htmlFor="payoutLimit">
                                        payout Limit
                                    </label>
                                    <input
                                        name="payoutLimit"
                                        type="number"
                                        defaultValue={details?.payoutLimit}
                                        onChange={SettingChangeHandler}></input>
                                </div>
                                <div className="settting-detail-percentage">
                                    <label htmlFor="rechargeLimit">
                                        Recharge Limit
                                    </label>
                                    <input
                                        type="number"
                                        name="rechargeLimit"
                                        defaultValue={details?.rechargeLimit}
                                        onChange={SettingChangeHandler}></input>
                                </div>
                                <div className="settting-detail">
                                    <label htmlFor="News">News</label>
                                    <textarea
                                        name="news"
                                        onChange={SettingChangeHandler}
                                        rows={6}
                                        cols={5}>
                                        {details.news}
                                    </textarea>
                                </div>
                                <div className="file_upload">
                                    <label htmlFor="">Banner</label>
                                    <div>
                                        <input
                                            type="file"
                                            onChange={fileHandler}
                                        />
                                        <img src={Fileuploaded} alt="" />
                                        {((Fileuploaded &&
                                            Fileuploaded != "NA") ||
                                            (base64 && base64 != "NA")) && (
                                            <button
                                                style={{ width: "fit-content" }}
                                                type="button"
                                                onClick={() => {
                                                    setBse64("NA");
                                                    setFile("NA");
                                                }}>
                                                Clear{" "}
                                            </button>
                                        )}
                                    </div>
                                </div>
                                <button type="submit">Save</button>
                            </form>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Settings;

import "./App.css";
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Dashboard from "./adminPortal/Components/dashboard/dashboard";
import UserList from "./adminPortal/Components/userList/userlist";
import Login from "./adminPortal/Components/Login";
import Referral from "./adminPortal/Components/userList/referral";
import UserListDashboard from "./adminPortal/Components/userList/Dashboard";
import Transactions from "./adminPortal/Components/userList/Transactions";
import Userdetails from "./adminPortal/Components/userList/userDetails";
import Settings from "./adminPortal/Components/userList/settings";
import BusinessUsers from "./adminPortal/Components/businessUsers/businessUsers";
import AdminTransactions from "./adminPortal/Components/transactions/Transaction";
import AdminSettings from "./adminPortal/Components/settings/settings";
import BusinessUserDetails from "./adminPortal/Components/businessUsers/userdetails";
import BusinessDashBoard from "./adminPortal/Components/businessUsers/Dashboard";
import BusinessTransactions from "./adminPortal/Components/businessUsers/Transactions";
import BusinessSettings from "./adminPortal/Components/businessUsers/settings";
import BusinessReferral from "./adminPortal/Components/businessUsers/referral";
import BusinessInfo from "./adminPortal/Components/businessUsers/businessinfo";
import PendingTransactions from "./adminPortal/Components/pendingTransactions/Transaction";
import AutoPay from "./adminPortal/Components/settings/autopay";
import WalletDebitTransaction from "./adminPortal/Components/walletDebitTransactions/Transaction"
import "react-toastify/dist/ReactToastify.css";
function App() {
    return (
        <div className="App">
            <ToastContainer />
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/userlist" element={<UserList />} />
                <Route
                    path="/userlist/userdetails/:userid"
                    element={<Userdetails />}
                />
                <Route
                    path="/userlist/userdetails/:id/Dashboard"
                    element={<UserListDashboard />}
                />
                <Route
                    path="/userlist/userdetails/:id/Transactions"
                    element={<Transactions />}
                />
                <Route
                    path="/Debit-transactions"
                    element={<WalletDebitTransaction />}
                />
                <Route
                    path="/userlist/userdetails/:id/Referral"
                    element={<Referral />}
                />
                <Route
                    path="/userlist/userdetails/:id/Settings"
                    element={<Settings />}
                />
                <Route
                    path="/businessUser/userdetails/:userid"
                    element={<BusinessUserDetails />}
                />
                <Route
                    path="/businessUser/userdetails/:id/Dashboard"
                    element={<BusinessDashBoard />}
                />
                <Route
                    path="/businessUser/userdetails/:id/Transactions"
                    element={<BusinessTransactions />}
                />
                <Route
                    path="/businessUser/userdetails/:id/Referral"
                    element={<BusinessReferral />}
                />
                <Route
                    path="/businessUser/userdetails/:id/Settings"
                    element={<BusinessSettings />}
                />
                <Route
                    path="/businessUser/userdetails/:id/info"
                    element={<BusinessInfo />}
                />
                <Route path="/BusinessUsers" element={<BusinessUsers />} />
                <Route path="/Transactions" element={<AdminTransactions />} />
                <Route
                    path="/pending-transactions"
                    element={<PendingTransactions />}
                />
                <Route path="/Settings" element={<AdminSettings />} />
                {/* <Route path="/AutoPay" element={<AutoPay />} /> */}
            </Routes>
        </div>
    );
}

export default App;

import React, { useEffect, useState } from "react";
import Navbar from "../dashboard/navbar";
import { toast } from "react-toastify";
import MainTopNav from "../maintopnav";
import TopNav from "../../Components/businessUsers/topNav";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../Spinner";
import "../Admin.css";
import Modal from "./modal";
import { FileUploader } from "react-drag-drop-files";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { IoMdArrowRoundBack } from "react-icons/io";
import { BsCloudUpload } from "react-icons/bs";
import SessionExpiredModal from "../401";
import S3FileUpload from "react-s3";
import { config, s3Config } from "../../../s3Config";
import { GrDocumentPdf } from "react-icons/gr";
import RejectModal from "./RejectModal";
window.Buffer = window.Buffer || require("buffer").Buffer;
function Referral() {
    const [sessionExpired, setSessionExpired] = useState(false);
    const [spinner, setSpinner] = useState(true);
    const [details, setDetails] = useState([]);
    const { id } = useParams();
    const Navigate = useNavigate();
    const [firstName, setFirstName] = useState();
    const [modal, setmodal] = useState(false);
    const token = JSON.parse(sessionStorage.getItem("token"));
    const [rejectModal, setRejectModal] = useState(false);
    const isKYCverified = sessionStorage.getItem("isKYCverified");
    const [uploadData, setUploadData] = useState([]);
    // businessInsidePhotoURL
    const [businessInsidePhotoURL, setbusinessInsidePhotoURL] = useState("");
    const [businessOutsidePhotoURL, setbusinessOutsidePhotoURL] = useState("");
    const [authorizedPANCardURL, setauthorizedPANCardURL] = useState("");
    const [businessProofURL, setbusinessProofURL] = useState("");

    const getdetails = () => {
        fetch(
            `${process.env.REACT_APP_BASE_URL}/admin/users?userId=${id}
            `,
            {
                headers: {
                    "content-type": "application/json;charset =UTF-8",
                    Authorization: `token ${token}`,
                },
            }
        )
            .then((Response) => Response.json())
            .then((response) => {
                console.log(response);
                if (response.Status === 1) {
                    setDetails(response.UserDetails);
                    setbusinessInsidePhotoURL(
                        response.UserDetails.businessInsidePhotoURL
                    );
                    setbusinessOutsidePhotoURL(
                        response.UserDetails.businessOutsidePhotoURL
                    );
                    setauthorizedPANCardURL(
                        response.UserDetails.authorizedPANCardURL
                    );
                    setbusinessProofURL(response.UserDetails.businessProofURL);
                }
                if (response.Status === 0) {
                    if (response.Message === "Invalid Token") {
                        setSessionExpired(true);
                        return;
                    }
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
                setSpinner(false);
            })
            .catch((err) => {
                setSpinner(false);
                toast.error(
                    `something went Wrong please try again after sometime`,
                    {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    }
                );
            });
    };
    useEffect(() => {
        setFirstName(sessionStorage.getItem("firstname"));
        getdetails();
    }, [token, id]);
    const backHandler = () => {
        Navigate("/BusinessUsers");
    };
    const approveHandler = () => {
        setmodal(true);
    };
    const Approve = (body) => {
        fetch(
            `${process.env.REACT_APP_BASE_URL}/admin/${id}/update-userdetails`,
            {
                method: "PUT",
                body: JSON.stringify(body),
                headers: {
                    "content-type": "application/json;charset =UTF-8",
                    Authorization: `token ${token}`,
                },
            }
        )
            .then((Response) => Response.json())
            .then((response) => {
                if (response.Status === 1) {
                    getdetails();
                    if (modal) {
                        setmodal(false);
                    } else {
                        setRejectModal(false);
                    }
                    toast.success(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
                if (response.Status === 0) {
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
                setSpinner(false);
            })
            .catch((err) => {
                setSpinner(false);
                toast.error(
                    `something went Wrong please try again after sometime`,
                    {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    }
                );
            });
    };
    console.log(uploadData);
    const handleChange = (file, type) => {
        console.log(file);
        console.log(type);

        const size = file.size / 2097152;
        if (size > 2) {
            alert("image size should be less than 2MB");
        }
        if (
            file.type === "image/png" ||
            file.type === "image/jpeg" ||
            file.type === "application/pdf"
        ) {
            const timestamp = Date.now();
            const newfile = new File(
                [file],
                `${timestamp}.${String(file.type).split("/")[1]}`
            );
            // setUploadData((prev) => {
            //     return [...prev, { [type]: file }];
            // });
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function() {
                S3FileUpload.uploadFile(newfile, s3Config)
                    .then((res) => {
                        console.log(res);
                        //
                        if (type === "businessInsidePhotoURL") {
                            setbusinessInsidePhotoURL(res.location);
                        } else if (type == "businessOutsidePhotoURL") {
                            setbusinessOutsidePhotoURL(res.location);
                        } else if (type == "authorizedPANCardURL") {
                            setauthorizedPANCardURL(res.location);
                        } else if (type == "businessProofURL") {
                            setbusinessProofURL(res.location);
                        }
                        setUploadData((prev) => [
                            ...prev,
                            {
                                [type]: `${timestamp}.${
                                    String(file.type).split("/")[1]
                                }`,
                            },
                        ]);
                    })
                    .catch((err) => {
                        alert(err);
                        console.log(err);
                    });
            };
            reader.onerror = function(error) {
                console.log("Error: ", error);
            };
        } else {
            alert("please upload image in PNG and JPEG format ");
        }
    };

    const fileuploadDesign = (
        <div className={["add-item"]}>
            <BsCloudUpload className={["upload-icon"]} />
            <h5>Drag Your Image here</h5>
            <p>(Only *.jpeg and *.png images will be accepted)</p>
        </div>
    );
    const updateHandler = () => {
        Approve(Object.assign({}, ...uploadData));
    };
    return (
        <>
            {sessionExpired && <SessionExpiredModal />}
            {modal && <Modal setmodal={setmodal} Approve={Approve} />}
            {rejectModal && (
                <RejectModal setmodal={setRejectModal} Approve={Approve} />
            )}
            <div className="ui">
                <div className="ui-leftside">
                    <div className="navbar">
                        <Navbar active="BusinessUsers" />
                    </div>
                </div>
                <div className="ui-rightside ">
                    <MainTopNav />
                    <div className="ui-wrapper">
                        <div className="User-name">
                            <button
                                className="back-button"
                                onClick={backHandler}>
                                <IoMdArrowRoundBack />
                                <p>Back</p>
                            </button>

                            <p
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "0.5rem",
                                }}>
                                {firstName}{" "}
                                {isKYCverified === "1" && (
                                    <CheckCircleIcon
                                        style={{ color: "green" }}
                                    />
                                )}
                            </p>
                        </div>
                        <div className="topNav">
                            <TopNav active="info" />
                        </div>
                        {spinner ? (
                            <Spinner />
                        ) : (
                            <div className="business-info">
                                <div className="business-approve">
                                    <h3>Business Details</h3>
                                    {details.isBusinessProfileApproved == 0 ? (
                                        <div style={{ display: "flex" }}>
                                            <button onClick={approveHandler}>
                                                Approve
                                            </button>
                                            <button
                                                onClick={() =>
                                                    setRejectModal(true)
                                                }>
                                                Reject
                                            </button>
                                        </div>
                                    ) : details.isBusinessProfileApproved ==
                                      1 ? (
                                        <p>Approved</p>
                                    ) : (
                                        <p style={{ backgroundColor: "red" }}>
                                            Rejected
                                        </p>
                                    )}
                                </div>
                                <div className="info">
                                    <h4>Company Name</h4>
                                    <span>:</span>
                                    <p>{details.companyName}</p>
                                </div>
                                <div className="info">
                                    <h4>Nature Of Business</h4>
                                    <span>:</span>
                                    <p>{details.natureofBusiness}</p>
                                </div>
                                <div className="info">
                                    <h4>Monthly Business Commitment</h4>
                                    <span>:</span>
                                    <p>{details.monthlyBusinessCommitment}</p>
                                </div>
                                <div className="info-pictures">
                                    <h4>Business Inside Photo</h4>
                                    <div className={["item-image-upload"]}>
                                        <FileUploader
                                            classes={["upload-image"]}
                                            multiple={false}
                                            handleChange={(e) => {
                                                handleChange(
                                                    e,
                                                    "businessInsidePhotoURL"
                                                );
                                            }}
                                            name="file"
                                            children={fileuploadDesign}
                                            // types={fileTypes}
                                        />
                                    </div>
                                    <div className={["uploaded-img"]}>
                                        {String(
                                            businessInsidePhotoURL
                                        ).includes(".pdf") ? (
                                            <GrDocumentPdf
                                                style={{ cursor: "pointer" }}
                                                size={45}
                                                onClick={() => {
                                                    window.open(
                                                        businessInsidePhotoURL,
                                                        "_blank"
                                                    );
                                                }}
                                            />
                                        ) : (
                                            <img
                                                onClick={() => {
                                                    window.open(
                                                        businessInsidePhotoURL,
                                                        "_blank"
                                                    );
                                                }}
                                                src={businessInsidePhotoURL}
                                                alt=""
                                                width={"200px"}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="info-pictures">
                                    <h4>Business Outside Photo</h4>
                                    <div className={["item-image-upload"]}>
                                        <FileUploader
                                            classes={["upload-image"]}
                                            multiple={false}
                                            handleChange={(e) => {
                                                handleChange(
                                                    e,
                                                    "businessOutsidePhotoURL"
                                                );
                                            }}
                                            name="file"
                                            children={fileuploadDesign}
                                        />
                                    </div>
                                    <div className={["uploaded-img"]}>
                                        {/* {buss} */}
                                        {String(
                                            businessOutsidePhotoURL
                                        ).includes(".pdf") ? (
                                            <GrDocumentPdf
                                                style={{ cursor: "pointer" }}
                                                size={45}
                                                onClick={() => {
                                                    window.open(
                                                        businessOutsidePhotoURL,
                                                        "_blank"
                                                    );
                                                }}
                                            />
                                        ) : (
                                            <img
                                                onClick={() => {
                                                    window.open(
                                                        businessOutsidePhotoURL,
                                                        "_blank"
                                                    );
                                                }}
                                                src={businessOutsidePhotoURL}
                                                alt=""
                                                width={"200px"}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="info-pictures">
                                    <h4>Authorized pancard</h4>
                                    <div className={["item-image-upload"]}>
                                        <FileUploader
                                            classes={["upload-image"]}
                                            multiple={false}
                                            handleChange={(e) => {
                                                handleChange(
                                                    e,
                                                    "authorizedPANCardURL"
                                                );
                                            }}
                                            name="file"
                                            children={fileuploadDesign}
                                            // types={fileTypes}
                                        />
                                    </div>
                                    <div className={["uploaded-img"]}>
                                        {String(authorizedPANCardURL).includes(
                                            ".pdf"
                                        ) ? (
                                            <GrDocumentPdf
                                                style={{ cursor: "pointer" }}
                                                size={45}
                                                onClick={() => {
                                                    window.open(
                                                        authorizedPANCardURL,
                                                        "_blank"
                                                    );
                                                }}
                                            />
                                        ) : (
                                            <img
                                                onClick={() => {
                                                    window.open(
                                                        authorizedPANCardURL,
                                                        "_blank"
                                                    );
                                                }}
                                                src={authorizedPANCardURL}
                                                alt=""
                                                width={"200px"}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="info-pictures">
                                    <h4>business Proof</h4>
                                    <div className={["item-image-upload"]}>
                                        <FileUploader
                                            classes={["upload-image"]}
                                            multiple={false}
                                            handleChange={(e) => {
                                                handleChange(
                                                    e,
                                                    "businessProofURL"
                                                );
                                            }}
                                            name="file"
                                            children={fileuploadDesign}
                                            // types={fileTypes}
                                        />
                                    </div>
                                    <div className={["uploaded-img"]}>
                                        {String(businessProofURL).includes(
                                            ".pdf"
                                        ) ? (
                                            <GrDocumentPdf
                                                style={{ cursor: "pointer" }}
                                                size={45}
                                                onClick={() => {
                                                    window.open(
                                                        businessProofURL,
                                                        "_blank"
                                                    );
                                                }}
                                            />
                                        ) : (
                                            <img
                                                onClick={() => {
                                                    window.open(
                                                        businessProofURL,
                                                        "_blank"
                                                    );
                                                }}
                                                src={businessProofURL}
                                                alt=""
                                                width={"200px"}
                                            />
                                        )}
                                    </div>
                                </div>

                                {/* {details.businessProofURL !== "NA" && (
                                    <a
                                        href={details.businessProofURL}
                                        target="_blank"
                                        rel="noreferrer">
                                        Business Proof
                                    </a>
                                )} */}
                                <button
                                    className="update-business-btn"
                                    onClick={updateHandler}>
                                    Update
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Referral;

import React from "react";
import "./modal.css";

// import { FaRegWindowClose } from "react-icons/fa";
function Modal(props) {
    const CancelHandler = (e) => {
        e.stopPropagation();
        props.setmodal(false);
    };
    const approvehandler = (e) => {
        e.stopPropagation();
        props.Approve({ isBusinessProfileApproved: 2 });
    };
    const openhandler = (e) => {
        e.stopPropagation();
    };
    return (
        <div className="modal-confirm" onClick={CancelHandler}>
            <div className="modal-content-confirm" onClick={openhandler}>
                {/* <div className="modal-content"> */}
                <button className="close-btn">
                    {/* <FaRegWindowClose size={27} /> */}
                </button>
                <p>Are you sure want to Reject the Business?</p>
                <div className="control-btns">
                    <button className="cancel-btn" onClick={CancelHandler}>
                        Cancel
                    </button>
                    <button className="delete-btn" onClick={approvehandler}>
                        Confirm
                    </button>
                    {/* </div> */}
                </div>
            </div>
        </div>
    );
}

export default Modal;

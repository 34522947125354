import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

export default function CircularIndeterminate() {
    return (
        // <Box
        //     sx={{
        //         display: "flex",
        //         alignItems: "center",
        //         justifyContent: "center",
        //     }}>
        //     <CircularProgress />
        // </Box>
        <div className="spin-wrapper">
            <div className="spinner">
                <div className="inner-spinner"></div>
            </div>
        </div>
    );
}

import React, { useEffect, useState } from "react";
import user from "../Assets/images/user.svg";
function Maintopnav() {
    const [name, setname] = useState();
    const details = JSON.parse(sessionStorage.getItem("userDetails"));
    useEffect(() => {
        setname(`${details.firstName} ${details.lastName}`);
    }, [details]);
    return (
        <div className="main-top-nav">
            <p>{name}</p>
            <img src={user} alt="/user" />
        </div>
    );
}

export default Maintopnav;

import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import logo from "../../Assets/images/dashboard/logo.svg";
import { MdDashboard } from "react-icons/md";
import { IoLogOutOutline } from "react-icons/io5";
import { FaUser } from "react-icons/fa";
import { FaUserTie } from "react-icons/fa";
import { IoTimer } from "react-icons/io5";
import { AiOutlineSetting, AiOutlineTransaction } from "react-icons/ai";
import { GrTransaction } from "react-icons/gr";
import { FaSortAmountDown } from "react-icons/fa";
function Navbar(props) {
    const Navigate = useNavigate();
    const isactive = props.active;
    const [userDetails, setUserDetails] = useState({});
    useEffect(() => {
        setUserDetails(JSON.parse(sessionStorage.getItem("userDetails")));
    }, []);
    const logoutHandler = (e) => {
        if (window.confirm("Are you Sure you want to Logout ")) {
            Navigate("/");
            sessionStorage.clear();
        }
    };
    return (
        <div className="navbar-main">
            <div className="logo">
                <img src={logo} alt="" />
            </div>
            <div className="nav-links">
                <NavLink
                    className={
                        isactive === "dashboard"
                            ? "navlinkactive"
                            : "navlinkinactive"
                    }
                    to="/dashboard">
                    <MdDashboard className="icons" size={22} />
                    <p>Dashboard</p>
                </NavLink>
                <NavLink
                    className={
                        isactive === "Userlist"
                            ? "navlinkactive"
                            : "navlinkinactive"
                    }
                    to="/userlist">
                    <FaUser className="icons" size={22} />
                    <p>User list</p>
                </NavLink>
                <NavLink
                    className={
                        isactive === "BusinessUsers"
                            ? "navlinkactive"
                            : "navlinkinactive"
                    }
                    to="/BusinessUsers">
                    <FaUserTie className="icons" size={22} />
                    <p>Business Users</p>
                </NavLink>
                <NavLink
                    className={
                        isactive === "Transactions"
                            ? "navlinkactive"
                            : "navlinkinactive"
                    }
                    to="/Transactions">
                    <AiOutlineTransaction
                        className="icons"
                        size={22}
                        color="white"
                    />
                    <p>Transactions</p>
                </NavLink>
                <NavLink
                    className={
                        isactive === "Wallet Debit Transactions"
                            ? "navlinkactive"
                            : "navlinkinactive"
                    }
                    to="/Debit-transactions">
                    <FaSortAmountDown
                        className="icons"
                        size={22}
                        color="white"
                    />
                    <p>Wallet Debit Transactions</p>
                </NavLink>
                <NavLink
                    className={
                        isactive === "PendingTransactions"
                            ? "navlinkactive"
                            : "navlinkinactive"
                    }
                    to="/pending-transactions">
                    <IoTimer className="icons" size={22} />
                    <p>Pending Transactions</p>
                </NavLink>
                {userDetails?.type === "SUPERADMIN" && (
                    <NavLink
                        className={
                            isactive === "settings"
                                ? "navlinkactive"
                                : "navlinkinactive"
                        }
                        to="/settings">
                        <AiOutlineSetting className="icons" size={25} />
                        <p>Settings</p>
                    </NavLink>
                )}
                {/* {userDetails?.type === "SUPERADMIN" && (
                    <NavLink
                        className={
                            isactive === "AutoPay"
                                ? "navlinkactive"
                                : "navlinkinactive"
                        }
                        to="/AutoPay">
                        <AiOutlineSetting className="icons" size={25} />
                        <p>Autopay Settings</p>
                    </NavLink>
                )} */}
                <button onClick={logoutHandler}>
                    <IoLogOutOutline className="icons" size={22} />
                    <p>Logout</p>
                </button>
            </div>
        </div>
    );
}

export default Navbar;

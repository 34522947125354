import React, { useState, useEffect } from "react";
import "../Admin.css";
import Spinner from "../Spinner";
import { toast } from "react-toastify";
function Modal(props) {
    const token = JSON.parse(sessionStorage.getItem("token"));
    const [spinner, setSpinner] = useState(true);
    const [transactionDetails, setTransactionDetails] = useState();
    const [date, setdate] = useState();
    const CancelHandler = (e) => {
        e.stopPropagation();
        props.setmodal(false);
    };

    const openhandler = (e) => {
        e.stopPropagation();
    };
    useEffect(() => {
        fetch(
            `${process.env.REACT_APP_BASE_URL}/admin/wallet-transactions/${props.id}
`,
            {
                headers: {
                    "content-type": "application/json;charset =UTF-8",
                    Authorization: `token ${token}`,
                },
            }
        )
            .then((Response) => Response.json())
            .then((response) => {
                console.log(response);
                if (response.Status === 1) {
                    setTransactionDetails(response.walletTransaction);
                    const datestring = String(
                        response.walletTransaction.transactionDateTime
                    ).split("T");
                    const transdate = new Date(datestring[0]);

                    const time = datestring[1].split(":");
                    console.log(time);
                    const month = transdate.toLocaleString("default", {
                        month: "short",
                    });
                    setdate(
                        `${transdate.getDate()} ${month} ${transdate.getFullYear()} ${
                            time[0]
                        }:${time[1]}`
                    );
                }
                if (response.Status === 0) {
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
                setSpinner(false);
            })
            .catch((err) => {
                setSpinner(false);
                toast.error(
                    `something went Wrong please try again after sometime`,
                    {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    }
                );
            });
    }, [props.id, token]);
    return (
        <div className="modal" onClick={CancelHandler}>
            <div className="modal-content" onClick={openhandler}>
                <div className="modal-content">
                    {spinner ? (
                        <Spinner />
                    ) : (
                        <div className="transactionDetails-wrapper">
                            <div className=" row">
                                <h3>INVOICE</h3>
                                <h4>:</h4>
                                <p>
                                    {" "}
                                    {transactionDetails.InvoiceFile !== "NA" ? (
                                        <a
                                            href={
                                                transactionDetails.InvoiceFile
                                            }
                                            target={"_blank"}
                                            rel="noreferrer">
                                            Download
                                        </a>
                                    ) : (
                                        <p>NA</p>
                                    )}
                                </p>
                            </div>
                            <div className="row ">
                                <h3>Transaction Done By</h3>
                                <h4>:</h4>
                                <p> {transactionDetails?.doneBy}</p>
                            </div>
                            <div className=" row">
                                <h3>Name</h3>
                                <h4>:</h4>
                                <p> {transactionDetails?.name}</p>
                            </div>
                            <div className=" row ">
                                <h3>EMAIL</h3>
                                <h4>:</h4>
                                <p>{transactionDetails.emailId}</p>
                            </div>
                            <div className=" row ">
                                <h3>Recharge PG</h3>
                                <h4>:</h4>
                                <p>{transactionDetails.paymentgateway}</p>
                            </div>

                            <div className=" row ">
                                <h3>Mobile Number</h3>
                                <h4>:</h4>
                                {transactionDetails.transactionType === 1 ? (
                                    <p> {transactionDetails?.contactNumber}</p>
                                ) : (
                                    <p> {transactionDetails?.mobileNumber}</p>
                                )}
                            </div>
                            {transactionDetails.transactionType === 2 && (
                                <div className=" row">
                                    <h3>Bank Account Number</h3>
                                    <h4>:</h4>
                                    <p>
                                        {transactionDetails?.bankAccountNumber}{" "}
                                    </p>
                                </div>
                            )}
                            {transactionDetails.transactionType === 2 && (
                                <div className=" row">
                                    <h3>Bank IFSC Code</h3>
                                    <h4>:</h4>
                                    <p>{transactionDetails?.bankIFSCCode} </p>
                                </div>
                            )}
                            <div className=" row">
                                <h3>Order id</h3>
                                <h4>:</h4>
                                <p>
                                    {" "}
                                    {transactionDetails.wallet_transactionId}
                                </p>
                            </div>
                            <div className=" row ">
                                <h3>UTR No</h3>
                                <h4>:</h4>
                                {transactionDetails.transactionType === 2 ? (
                                    <p>{transactionDetails.utr}</p>
                                ) : (
                                    <p>NA</p>
                                )}
                            </div>

                            <div className=" row">
                                <h3>ipAddress</h3>
                                <h4>:</h4>
                                <p>{transactionDetails.ipAddress}</p>
                            </div>
                            <div className=" row">
                                <h3>Sub Total</h3>
                                <h4>:</h4>
                                <p>₹{transactionDetails.amount}</p>
                            </div>
                            <div className=" row">
                                <h3>Surcharges</h3>
                                <h4>:</h4>
                                <p>
                                    ₹
                                    {transactionDetails.paymentgateway == "pg1"
                                        ? transactionDetails.walletCommissionPercentage
                                        : transactionDetails.walletCommissionPercentage_pinelabs}
                                    %
                                </p>
                            </div>
                            <div className=" row">
                                <h3>Charges</h3>
                                <h4>:</h4>
                                <p>₹{transactionDetails.charges}</p>
                            </div>
                            <div className="row">
                                <h3>GST Amount</h3>
                                <h4>:</h4>
                                <p>₹{transactionDetails.tax}</p>
                            </div>
                            <div className="row ">
                                <h3>Transaction Type</h3>
                                <h4>:</h4>
                                <p>
                                    {transactionDetails.transactionType === 1 &&
                                        "Recharge/Topup"}
                                    {transactionDetails.transactionType === 2 &&
                                        "Payout"}
                                    {transactionDetails.transactionType === 3 &&
                                        "Wallet Debit Transaction"}
                                    {transactionDetails.transactionType === 4 &&
                                        "Wallet Credit Transaction"}
                                    {transactionDetails.transactionType === 5 &&
                                        "Payout"}
                                    {transactionDetails.transactionType === 6 &&
                                        " Payout Charges Debit Transaction"}
                                    {transactionDetails.transactionType === 7 &&
                                        "Referral Commission"}
                                    {transactionDetails.transactionType === 8 &&
                                        " BBPS Transaction"}
                                    {transactionDetails.transactionType === 9 &&
                                        " Admin debit"}
                                </p>
                            </div>
                            <div className=" row">
                                <h3>Date</h3>
                                <h4>:</h4>
                                <p>{date}</p>
                            </div>
                            <div className=" row">
                                <h3>Status</h3>
                                <h4>:</h4>
                                <p>
                                    {" "}
                                    {transactionDetails?.transactionStatus ===
                                        2 && (
                                        <span className="pending-span">
                                            Pending
                                        </span>
                                    )}
                                    {transactionDetails?.transactionStatus ===
                                        1 && (
                                        <span className="completed-span">
                                            Processed
                                        </span>
                                    )}
                                    {transactionDetails?.transactionStatus ===
                                        3 && (
                                        <span className="failed-span">
                                            Failed
                                        </span>
                                    )}
                                    {transactionDetails?.transactionStatus ===
                                        4 && (
                                        <span className="reversed-span">
                                            Reversed
                                        </span>
                                    )}
                                </p>
                            </div>
                            <div className=" row">
                                <h3>Notes</h3>
                                <h4>:</h4>
                                <p>{transactionDetails.notes}</p>
                            </div>
                            <div className=" row">
                                <h3>Description</h3>
                                <h4>:</h4>
                                <p>{transactionDetails.description}</p>
                            </div>
                            <div className=" row">
                                <h3>razorpay TransactionId</h3>
                                <h4>:</h4>
                                <p>
                                    {transactionDetails.razorpay_TransactionId}
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Modal;

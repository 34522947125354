import React, { useState } from "react";
import "./modal.css";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
function Modal(props) {
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();
    const token = JSON.parse(sessionStorage.getItem("token"));
    const CancelHandler = (e) => {
        e.stopPropagation();
        props.setmodal(false);
    };

    const openhandler = (e) => {
        e.stopPropagation();
    };
    const onSubmit = (data) => {
        let header = {
            headers: {
                "content-type": "application/json;charset =UTF-8",
                Authorization: `token ${token}`,
            },
        };
        fetch(`${process.env.REACT_APP_BASE_URL}/admin/add-debitTransaction`, {
            method: "POST",
            body: JSON.stringify({ ...data, userId: id }),
            ...header,
        })
            .then((Response) => Response.json())
            .then((response) => {
                setLoading(false);
                if (response.Status === 1) {
                    // CancelHandler();
                    props.setmodal(false);
                    props.setspinner();
                    props.dateTransactions();
                    toast.success(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
                if (response.Status === 0) {
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
                toast.error(
                    `something went Wrong please try again after sometime`,
                    {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    }
                );
            });
    };

    return (
        <div className="modal-confirm" onClick={CancelHandler}>
            <div
                className="modal-content-payment"
                onClick={openhandler}
                style={{ cursor: loading ? "progress" : "default" }}>
                {/* <div className="modal-content"> */}
                <button className="close-btn">
                    {/* <FaRegWindowClose size={27} /> */}
                </button>
                <h3>Wallet Debit</h3>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="modal_paymnet_formcontrol">
                        <label htmlFor="orderId">Order Id </label>
                        <div>
                            <input
                                type="text"
                                placeholder="order Id"
                                {...register("orderId", {
                                    required: false,

                                    pattern: {
                                        value: /^[a-zA-Z0-9]*$/,
                                        message:
                                            "Only alphanumeric characters are allowed.",
                                    },
                                    maxLength: {
                                        value: 20,
                                        message:
                                            "Maximum length is 20 characters.",
                                    },
                                })}
                            />
                            {errors.orderId && (
                                <span>{errors.orderId.message}</span>
                            )}
                        </div>
                    </div>
                    <div className="modal_paymnet_formcontrol">
                        <label htmlFor="Date of Transaction">Amount</label>
                        <div>
                            <input
                                type="number"
                                placeholder="Enter Amount"
                                {...register("amount", {
                                    required: true,
                                })}
                            />
                            {errors.amount && (
                                <span>This field is required</span>
                            )}
                        </div>
                    </div>
                    <div className="modal_paymnet_formcontrol">
                        <label htmlFor="Remarks">Remarks</label>
                        <div>
                            <textarea
                                rows={6}
                                {...register("remarks", {
                                    required: true,
                                })}
                                placeholder="Remarks..."></textarea>
                            {errors.remarks && (
                                <span>This field is required</span>
                            )}
                        </div>
                    </div>

                    <input
                        type="submit"
                        style={{ cursor: loading ? "progress" : "default" }}
                    />
                </form>
                {/* <div className="control-btns">
                    <button className="cancel-btn" onClick={CancelHandler}>
                        Cancel
                    </button>
                    <button className="delete-btn" onClick={approvehandler}>
                        Confirm
                    </button>
                </div> */}
            </div>
        </div>
    );
}

export default Modal;

import React, { useEffect, useState } from "react";
import Navbar from "../dashboard/navbar";
import { toast } from "react-toastify";
import Spinner from "../Spinner";
import TopNav from "./topNav";
import { useNavigate, useParams } from "react-router-dom";
import MainTopNav from "../maintopnav";
import { IoMdArrowRoundBack } from "react-icons/io";

import Modal from "../401";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
function Userdetails() {
    const Navigate = useNavigate();

    const [firstName, setFirstName] = useState();
    const { userid } = useParams();
    const token = JSON.parse(sessionStorage.getItem("token"));
    const [spinner, setSpinner] = useState(true);
    const [details, setDetails] = useState();

    const [sessionExpired, setSessionExpired] = useState(false);
    const [dob, setdob] = useState();
    const [edit, setEdit] = useState(false);
    const [referCode, setreferCode] = useState("");
    const getuserDetails = () => {
        fetch(
            `${process.env.REACT_APP_BASE_URL}/admin/users?userId=${userid}`,
            {
                headers: {
                    "content-type": "application/json;charset =UTF-8",
                    Authorization: `token ${token}`,
                },
            }
        )
            .then((Response) => Response.json())
            .then((response) => {
                console.log(response);
                if (response.Status === 1) {
                    setFirstName(response.UserDetails.companyName);
                    setDetails(response.UserDetails);
                    setreferCode(response.UserDetails.referredByCode);
                    setdob(new Date(response.UserDetails.DOB));
                    sessionStorage.setItem(
                        "isKYCverified",
                        response.UserDetails.isKYCverified
                    );
                    sessionStorage.setItem(
                        "firstname",
                        response.UserDetails.companyName
                    );
                }

                if (response.Status === 0) {
                    if (response.Message === "Invalid Token") {
                        setSessionExpired(true);
                        return;
                    }
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
                setSpinner(false);
            })
            .catch((err) => {
                setSpinner(false);
                toast.error(
                    `something went Wrong please try again after sometime`,
                    {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    }
                );
            });
    };
    useEffect(() => {
        setFirstName(sessionStorage.getItem("firstname"));
        getuserDetails();
    }, [token, userid]);
    const backHandler = () => {
        Navigate("/BusinessUsers");
    };
    const edithandler = (e) => {
        setreferCode(e.target.value);
    };
    const referSubmitHandler = (e) => {
        e.preventDefault();
        setSpinner(true);
        fetch(
            `${process.env.REACT_APP_BASE_URL}/admin/${userid}/update-userdetails`,
            {
                method: "PUT",
                body: JSON.stringify({ referredByCode: referCode }),
                headers: {
                    "content-type": "application/json;charset =UTF-8",
                    Authorization: `token ${token}`,
                },
            }
        )
            .then((Response) => Response.json())
            .then((response) => {
                console.log(response);
                if (response.Status === 1) {
                    getuserDetails();
                    setEdit(false);
                }
                if (response.Status === 0) {
                    if (response.Message === "Invalid Token") {
                        setSessionExpired(true);
                        return;
                    }
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
                setSpinner(false);
            })
            .catch((err) => {
                setSpinner(false);
                toast.error(
                    `something went Wrong please try again after sometime`,
                    {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    }
                );
            });
    };

    return (
        <>
            {sessionExpired && <Modal />}

            <div className="ui">
                <div className="ui-leftside">
                    <div className="navbar">
                        <Navbar active="BusinessUsers" />
                    </div>
                </div>
                <div className="ui-rightside ">
                    <MainTopNav />
                    <div className="ui-wrapper">
                        <div className="User-name">
                            <button
                                className="back-button"
                                onClick={backHandler}>
                                <IoMdArrowRoundBack />
                                <p>Back</p>
                            </button>{" "}
                            <p
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "0.5rem",
                                }}>
                                {details?.companyName}{" "}
                                {details?.isKYCverified === 1 && (
                                    <CheckCircleIcon
                                        style={{ color: "green" }}
                                    />
                                )}
                            </p>
                        </div>
                        <div className="topNav">
                            <TopNav active="userdetails" />
                        </div>
                        <div className="userdetails-wrapper">
                            {spinner ? (
                                <Spinner />
                            ) : details ? (
                                <div className="user-data-container">
                                    <div className="user-data ">
                                        <img
                                            src={details.profilePic}
                                            alt="profilepic "
                                        />
                                        <h3>{details.firstName}</h3>
                                    </div>
                                    <div className="user-data">
                                        <h4>
                                            Aadhar number <span>:</span>
                                        </h4>
                                        <p>{details.aadharNumber}</p>
                                    </div>
                                    <div className="user-data">
                                        <h4>
                                            DOB <span>:</span>
                                        </h4>
                                        <p>
                                            {String(dob.getDate()).padStart(
                                                2,
                                                "0"
                                            )}
                                            -
                                            {String(dob.getMonth()).padStart(
                                                2,
                                                "0"
                                            )}
                                            -{dob.getFullYear()}
                                        </p>
                                    </div>
                                    <div className="user-data">
                                        <h4>
                                            Age <span>:</span>
                                        </h4>
                                        <p>
                                            {new Date().getFullYear() -
                                                new Date(
                                                    details.DOB
                                                ).getFullYear()}
                                        </p>
                                    </div>

                                    <div className="user-data">
                                        <h4>
                                            Gender <span>:</span>
                                        </h4>
                                        <p>{details.gender}</p>
                                    </div>

                                    <div className="user-data">
                                        <h4>
                                            Address <span>:</span>
                                        </h4>
                                        <p className="user-data-address">
                                            {details.address}
                                        </p>
                                    </div>
                                    <div className="user-data">
                                        <h4>
                                            Email Id <span>:</span>
                                        </h4>
                                        <p>{details.emailId}</p>
                                    </div>
                                    <div className="user-data">
                                        <h4>
                                            Mobile Number <span>:</span>
                                        </h4>
                                        <p>{details.mobileNumber}</p>
                                    </div>
                                    <div className="user-data">
                                        <h4>
                                            Referral Code <span>:</span>
                                        </h4>
                                        <p>{details.myReferralCode}</p>
                                    </div>
                                    <div className="user-data">
                                        <h4>
                                            Referred By Code <span>:</span>
                                        </h4>

                                        {edit ? (
                                            <form
                                                className="add-refer-byCode"
                                                onSubmit={referSubmitHandler}>
                                                <input
                                                    type="text"
                                                    value={referCode}
                                                    onChange={edithandler}
                                                />
                                                <button>Submit</button>
                                            </form>
                                        ) : (
                                            <div className="edit-refer-code">
                                                <p>
                                                    {details.referredByCode}
                                                    {details.referredbyCompanyName !=
                                                        "NA" &&
                                                        `(${details.referredbyCompanyName})`}
                                                </p>
                                                <button
                                                    onClick={() => {
                                                        setEdit(true);
                                                    }}>
                                                    Edit
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                    <div className="user-data">
                                        <h4>
                                            Payment Page Id <span>:</span>
                                        </h4>
                                        <p>{details.paymentPageId}</p>
                                    </div>
                                    <div className="user-data">
                                        <h4>
                                            Wallet Amount <span>:</span>
                                        </h4>
                                        <p>₹ {details.walletAmount}</p>
                                    </div>
                                    <div className="user-data">
                                        <h4>
                                            Wallet Commission Percentage{" "}
                                            <span>:</span>
                                        </h4>
                                        <p>
                                            {details.walletCommissionPercentage}
                                            %
                                        </p>
                                    </div>

                                    <h3 className="detail-header">
                                        Business Details
                                    </h3>
                                    <div className="user-data">
                                        <h4>
                                            Company Name <span>:</span>
                                        </h4>
                                        <p>{details.companyName}</p>
                                    </div>
                                    <div className="user-data">
                                        <h4>
                                            Nature of Business <span>:</span>
                                        </h4>
                                        <p>{details.natureofBusiness}</p>
                                    </div>
                                    <div className="user-data">
                                        <h4>
                                            Monthly Business Commitment{" "}
                                            <span>:</span>
                                        </h4>
                                        <p>
                                            {details.monthlyBusinessCommitment}
                                        </p>
                                    </div>
                                </div>
                            ) : (
                                <div style={{ textAlign: "center" }}>
                                    No Data Found
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Userdetails;

import React, { useEffect, useState, useRef } from "react";
import Navbar from "../dashboard/navbar";
import { toast } from "react-toastify";
import Spinner from "../Spinner";
import "../Admin.css";
import MainTopNav from "../maintopnav";
import Modal from "./Modal";
import { FiSearch } from "react-icons/fi";
import SessionExpiredModal from "../401";
function Transactions() {
    const fromref = useRef();
    const statusref = useRef();
    const pgref = useRef();
    const searchref = useRef();
    const toref = useRef();
    const [disableType, setDisableType] = useState("");
    const token = JSON.parse(sessionStorage.getItem("token"));
    const [spinner, setSpinner] = useState(true);
    const [WalletTransactions, setWalletTransactions] = useState([]);
    const [prevOffset, setPrevOffset] = useState(0);
    const [currentOffset, setCurrentOffset] = useState();
    const [modal, setmodal] = useState(false);
    const [key, setkey] = useState("");
    const [offset, setoffset] = useState();
    const [prevdisable, setprevdisable] = useState(false);
    const [nextdisable, setnextdisble] = useState(false);

    // const [searchload, setSearchload] = useState(true);
    const [sessionExpired, setSessionExpired] = useState(false);
    let offsetState;
    useEffect(() => {
        offsetState = true;
    }, []);

    const gettransaction = (data) => {
        setSpinner(true);
        fetch(
            `${process.env.REACT_APP_BASE_URL}/admin/wallet-transactions
            `,
            {
                method: "POST",
                body: JSON.stringify({ ...data }),
                headers: {
                    "content-type": "application/json;charset =UTF-8",
                    Authorization: `token ${token}`,
                },
            }
        )
            .then((Response) => Response.json())
            .then((response) => {
                console.log(response);
                if (response.Status === 1) {
                    setWalletTransactions(response.walletTransactions);
                    setCurrentOffset(response.offset);
                    if (offset == response.offset) {
                        setprevdisable(true);
                    }
                    if (response.offset == -1) {
                        setnextdisble(true);
                    }
                    if (offsetState) {
                        setoffset(response.offset);
                        offsetState = false;
                        setprevdisable(true);
                    }
                }
                if (response.Status === 0) {
                    if (response.Message === "Invalid Token") {
                        setSessionExpired(true);
                        return;
                    }
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
                setSpinner(false);
            })
            .catch((err) => {
                setSpinner(false);
                toast.error(
                    `something went Wrong please try again after sometime`,
                    {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    }
                );
            });
    };
    useEffect(() => {
        gettransaction({ offset: 0 });
    }, [token]);
    const transactionsHandler = () => {
        // fromDate: `${current.getFullYear()}-${current.getMonth()}-${current.getDay()}`,
        //     toDate: `${to.getFullYear()}-${to.getMonth()}-${to.getDay()}`,
        const current = new Date(fromref.current.value);
        const to = new Date(toref.current.value);
        const pg = pgref.current.value;
        const status = statusref.current.value;

        let Body = { offset: 0, fromDate: current, toDate: to };
        if (disableType === "STATUS") {
            Body = { ...Body, paymentgateway: pg, transactionType: 1 };
        } else if (disableType === "PG") {
            Body = { ...Body, transactionStatus: status, transactionType: 2 };
        }

        gettransaction({
            ...Body,
        });
    };
    const prevHandler = () => {
        const current = new Date(fromref.current.value);
        const to = new Date(toref.current.value);
        if (prevOffset === 0) {
            return;
        }
        setnextdisble(false);

        const pg = pgref.current.value;
        const status = statusref.current.value;

        let Body = {
            offset: prevOffset - offset,
            fromDate: current,
            toDate: to,
        };
        if (disableType === "STATUS") {
            Body = { ...Body, paymentgateway: pg, transactionType: 1 };
        } else if (disableType === "PG") {
            Body = { ...Body, transactionStatus: status, transactionType: 2 };
        }
        gettransaction({
            ...Body,
        });

        setPrevOffset(prevOffset - offset);
        window.scrollTo({ top: 0, behavior: "smooth" });
    };
    const nexthandler = () => {
        const current = new Date(fromref.current.value);
        const to = new Date(toref.current.value);
        if (currentOffset !== -1) {
            setprevdisable(false);
            setPrevOffset(currentOffset);
            const pg = pgref.current.value;
            const status = statusref.current.value;

            let Body = { offset: currentOffset, fromDate: current, toDate: to };
            if (disableType === "STATUS") {
                Body = { ...Body, paymentgateway: pg, transactionType: 1 };
            } else if (disableType === "PG") {
                Body = {
                    ...Body,
                    transactionStatus: status,
                    transactionType: 2,
                };
            }
            gettransaction({
                ...Body,
            });
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
    };
    const modalhandler = (e) => {
        setkey(e.currentTarget.getAttribute("id"));
        setmodal(true);
    };
    const searchHandler = (e) => {
        setSpinner(true);
        fetch(
            `${process.env.REACT_APP_BASE_URL}/admin/wallet-transactions/${searchref.current.value}
`,
            {
                headers: {
                    "content-type": "application/json;charset =UTF-8",
                    Authorization: `token ${token}`,
                },
            }
        )
            .then((Response) => Response.json())
            .then((response) => {
                console.log(response);
                if (response.Status === 1) {
                    setprevdisable(true);
                    setnextdisble(true);
                    if (response?.walletTransaction?.razorpay_TransactionId) {
                        setWalletTransactions([response.walletTransaction]);
                    } else {
                        setWalletTransactions([]);
                    }
                }
                setSpinner(false);
            })
            .catch((err) => {
                setSpinner(false);
                toast.error(
                    `something went Wrong please try again after sometime`,
                    {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    }
                );
            });
    };
    const ClearHandler = () => {
        offsetState = true;
        setprevdisable(false);
        setnextdisble(false);
        gettransaction({ offset: 0 });
        searchref.current.value = "";
    };
    const resetForm = () => {
        pgref.current.value = "";
        fromref.current.value = "";
        toref.current.value = "";
        statusref.current.value = "";
        setDisableType("");
        gettransaction({
            offset: prevOffset,
        });
    };
    const refreshHandler = () => {
        const fromDate = fromref.current.value;
        const ToDate = toref.current.value;
        if (fromDate && ToDate) {
            const current = new Date(fromref.current.value);
            const to = new Date(toref.current.value);

            const pg = pgref.current.value;
            const status = statusref.current.value;

            let Body = {
                offset: prevOffset ? prevOffset : 0,
                fromDate: current,
                toDate: to,
            };
            if (disableType === "STATUS") {
                Body = { ...Body, paymentgateway: pg, transactionType: 1 };
            } else if (disableType === "PG") {
                Body = {
                    ...Body,
                    transactionStatus: status,
                    transactionType: 2,
                };
            }
            gettransaction({
                ...Body,
            });
        } else {
            gettransaction({
                offset: prevOffset,
            });
        }
    };
    return (
        <>
            {sessionExpired && <SessionExpiredModal />}
            {modal && <Modal setmodal={setmodal} id={key} />}
            <div className="ui">
                <div className="ui-leftside">
                    <div className="navbar">
                        <Navbar active="Transactions" />
                    </div>
                </div>
                <div className="ui-rightside ">
                    <MainTopNav />
                    <div className="ui-wrapper">
                        <div className="Table" style={{ overflowX: "auto" }}>
                            <div
                                className="filter-wrapper"
                                style={{
                                    gridTemplateColumns: "1fr",
                                    gap: "1rem",
                                }}>
                                <div className="search-data">
                                    <input
                                        type={"text"}
                                        placeholder="Search by razorypay ID"
                                        ref={searchref}
                                    />
                                    <FiSearch
                                        className="search-icon"
                                        size={23}
                                        color="#6865f0"
                                    />
                                    <button onClick={searchHandler}>
                                        Search
                                    </button>
                                    <button
                                        onClick={ClearHandler}
                                        style={{ background: "red" }}>
                                        Clear
                                    </button>
                                </div>
                                <div className="dateinputs">
                                    <input
                                        type={"date"}
                                        ref={fromref}
                                        max={
                                            new Date()
                                                .toISOString()
                                                .split("T")[0]
                                        }
                                    />
                                    <input
                                        type={"date"}
                                        ref={toref}
                                        max={
                                            new Date()
                                                .toISOString()
                                                .split("T")[0]
                                        }
                                    />
                                    <select
                                        ref={pgref}
                                        disabled={disableType === "PG"}
                                        onChange={(e) =>
                                            setDisableType(
                                                e.target.value.length > 0
                                                    ? "STATUS"
                                                    : ""
                                            )
                                        }>
                                        <option value="">Select PG</option>
                                        <option value="razorpay">
                                            Razorpay
                                        </option>
                                        <option value="pinelabs">
                                            PineLabs
                                        </option>
                                    </select>
                                    <select
                                        ref={statusref}
                                        disabled={disableType === "STATUS"}
                                        onChange={(e) =>
                                            setDisableType(
                                                e.target.value.length > 0
                                                    ? "PG"
                                                    : ""
                                            )
                                        }>
                                        <option value="">
                                            Transaction Status
                                        </option>
                                        <option value="1">Success</option>
                                        <option value="2">Pending</option>
                                        <option value="3">Failed</option>
                                        <option value="4">Reversed</option>
                                    </select>
                                    <button onClick={transactionsHandler}>
                                        Go
                                    </button>
                                    <button onClick={refreshHandler}>
                                        Refresh
                                    </button>
                                    <button onClick={resetForm}>
                                        Reset Form
                                    </button>
                                </div>
                            </div>
                            {spinner ? (
                                <Spinner />
                            ) : (
                                <table>
                                    <thead>
                                        <tr>
                                            <td>Date</td>
                                            <td>Order ID</td>
                                            <td>Done By</td>
                                            <td>Type</td>
                                            <td>PGType</td>
                                            <td>WithDrawal</td>
                                            <td>Deposits</td>
                                            <td>Status</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {WalletTransactions?.length > 0 ? (
                                            WalletTransactions.map((item) => {
                                                const datestring = String(
                                                    item.transactionDateTime
                                                ).split("T");
                                                const date = new Date(
                                                    datestring[0]
                                                );
                                                const month = date.toLocaleString(
                                                    "default",
                                                    { month: "short" }
                                                );
                                                const time = datestring[1].split(
                                                    ":"
                                                );
                                                return (
                                                    <tr
                                                        className="transactions-row "
                                                        key={
                                                            item.wallet_transactionId
                                                        }
                                                        id={
                                                            item.wallet_transactionId
                                                        }
                                                        onClick={modalhandler}>
                                                        <td>
                                                            {" "}
                                                            {String(
                                                                date.getDate()
                                                            ).padStart(
                                                                2,
                                                                "0"
                                                            )}{" "}
                                                            {month}{" "}
                                                            {date.getFullYear()}{" "}
                                                            {`${time[0]}:${time[1]}`}
                                                        </td>
                                                        <td>
                                                            {
                                                                item.wallet_transactionId
                                                            }
                                                        </td>
                                                        <td
                                                            style={{
                                                                maxWidth:
                                                                    "15rem",
                                                                wordBreak:
                                                                    "break-all",
                                                            }}>
                                                            {item?.userType ==
                                                            "BUSINESS"
                                                                ? item.companyName
                                                                : item?.firstName}
                                                        </td>
                                                        <td>
                                                            {" "}
                                                            {item?.transactionType ===
                                                                1 && (
                                                                <span>
                                                                    Recharge
                                                                </span>
                                                            )}
                                                            {item?.transactionType ===
                                                                2 && (
                                                                <span>
                                                                    Payout
                                                                </span>
                                                            )}
                                                            {item?.transactionType ===
                                                                3 && (
                                                                <span>
                                                                    Wallet Debit
                                                                </span>
                                                            )}
                                                            {item?.transactionType ===
                                                                4 && (
                                                                <span>
                                                                    Wallet
                                                                    Credit
                                                                </span>
                                                            )}
                                                            {item?.transactionType ===
                                                                5 && (
                                                                <span>
                                                                    payout
                                                                    refund
                                                                </span>
                                                            )}
                                                            {item?.transactionType ===
                                                                6 && (
                                                                <span>
                                                                    payout
                                                                    charges
                                                                </span>
                                                            )}
                                                            {item?.transactionType ===
                                                                7 && (
                                                                <span>
                                                                    wallet
                                                                    commission{" "}
                                                                    <br />
                                                                    for referred
                                                                    users
                                                                </span>
                                                            )}
                                                            {item?.transactionType ===
                                                                8 && (
                                                                <span>
                                                                    BBPS
                                                                    Transactions
                                                                </span>
                                                            )}
                                                            {item?.transactionType ===
                                                                9 && (
                                                                <span>
                                                                    Admin Debit
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td>
                                                            {
                                                                item.paymentgateway
                                                            }
                                                        </td>
                                                        <td>
                                                            {" "}
                                                            {(item?.transactionType ===
                                                                2 ||
                                                                item?.transactionType ===
                                                                    3 ||
                                                                item?.transactionType ===
                                                                    6 ||
                                                                item?.transactionType ===
                                                                    8 ||
                                                                item?.transactionType ===
                                                                    9) && (
                                                                <span
                                                                    style={{
                                                                        color:
                                                                            "#F0532D",
                                                                        fontWeight: 500,
                                                                    }}>
                                                                    ₹
                                                                    {
                                                                        item?.amount
                                                                    }
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td>
                                                            {(item?.transactionType ===
                                                                1 ||
                                                                item?.transactionType ===
                                                                    4 ||
                                                                item?.transactionType ===
                                                                    5 ||
                                                                item?.transactionType ===
                                                                    7) && (
                                                                <span
                                                                    style={{
                                                                        color:
                                                                            "#0DB14B",
                                                                        fontWeight: 500,
                                                                    }}>
                                                                    ₹
                                                                    {
                                                                        item?.amount
                                                                    }
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td>
                                                            {" "}
                                                            {item?.transactionStatus ===
                                                                2 && (
                                                                <span className="pending-span">
                                                                    {" "}
                                                                    Pending
                                                                </span>
                                                            )}
                                                            {item?.transactionStatus ===
                                                                1 && (
                                                                <span className="completed-span">
                                                                    {" "}
                                                                    Processed
                                                                </span>
                                                            )}
                                                            {item?.transactionStatus ===
                                                                3 && (
                                                                <span className="failed-span">
                                                                    {" "}
                                                                    Failed
                                                                </span>
                                                            )}
                                                            {item?.transactionStatus ===
                                                                4 && (
                                                                <span className="reversed-span">
                                                                    {" "}
                                                                    Reversed
                                                                </span>
                                                            )}
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td
                                                    colSpan={5}
                                                    style={{
                                                        fontWeight: 500,
                                                        textAlign: "center",
                                                    }}>
                                                    No Transactions Found
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td
                                                colSpan={6}
                                                style={{
                                                    textAlign: "right",
                                                }}>
                                                <button
                                                    className="controlButtons"
                                                    onClick={prevHandler}
                                                    disabled={prevdisable}>
                                                    Previous
                                                </button>
                                                <button
                                                    onClick={nexthandler}
                                                    className="controlButtons"
                                                    disabled={nextdisable}
                                                    style={{
                                                        marginLeft: "1rem",
                                                    }}>
                                                    Next
                                                </button>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Transactions;

import React, { useEffect, useState } from "react";
import Navbar from "../dashboard/navbar";
import { toast } from "react-toastify";
import Spinner from "../Spinner";
import MainTopNav from "../maintopnav";
import moment from "moment/moment.js";
import Modal from "../401";
function Dashboard() {
    const [sessionExpired, setSessionExpired] = useState(false);
    const token = JSON.parse(sessionStorage.getItem("token"));
    let months = [
        { value: "Jan", option: "January" },
        { value: "Feb", option: "February" },
        { value: "Mar", option: "March" },
        { value: "Apr", option: "April" },
        { value: "May", option: "May" },
        { value: "Jun", option: "June" },
        { value: "Jul", option: "July" },
        { value: "Aug", option: "August" },
        { value: "Sep", option: "September" },
        { value: "Oct", option: "October" },
        { value: "Nov", option: "November" },
        { value: "Dec", option: "December" },
    ];

    const [spinner, setSpinner] = useState(true);
    const [dashboard, setDashboard] = useState([]);
    const [monthData, setMonthData] = useState({});
    const [allMonths, setAllmonths] = useState([]);
    let date = new Date();
    let currentMonth = date.toLocaleString("en-us", { month: "short" });
    function getMonthName(monthNumber) {
        var monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];

        return monthNames[monthNumber - 1];
    }

    // Function to get the short month name based on a month number
    function getShortMonthName(monthNumber) {
        var shortMonthNames = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];

        return shortMonthNames[monthNumber - 1];
    }
    const getmo = (month, year) => {
        var pastMonths = [];

        for (var i = 0; i < 6; i++) {
            var currentMonth = month - i;
            var currentYear = year;

            if (currentMonth < 1) {
                currentMonth = 12 + currentMonth;
                currentYear--;
            }

            var monthName = getMonthName(currentMonth);
            var shortMonthName = getShortMonthName(currentMonth);

            var pastMonth = {
                month: monthName,
                longMonth: shortMonthName,
                year: currentYear,
            };

            pastMonths.push(pastMonth);
        }
        setAllmonths(pastMonths);
    };

    const getmonthData = (month, year) => {
        fetch(
            `${process.env.REACT_APP_BASE_URL}/dashboard/admin?Month=${month}&Year=${year}`,
            {
                headers: {
                    "content-type": "application/json;charset =UTF-8",
                    Authorization: `token ${token}`,
                },
            }
        )
            .then((Response) => Response.json())
            .then((response) => {
                if (response.Status === 1) {
                    setMonthData(response.Dashboard);
                }
                if (response.Status === 0) {
                    if (response.Message === "Invalid Token") {
                        setSessionExpired(true);
                        return;
                    }
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
                setSpinner(false);
            })
            .catch((err) => {
                setSpinner(false);
                toast.error(
                    `something went Wrong please try again after sometime`,
                    {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    }
                );
            });
    };
    useEffect(() => {
        fetch(`${process.env.REACT_APP_BASE_URL}/dashboard/admin/user-stats`, {
            headers: {
                "content-type": "application/json;charset =UTF-8",
                Authorization: `token ${token}`,
            },
        })
            .then((Response) => Response.json())
            .then((response) => {
                if (response.Status === 1) {
                    let currentYear = new Date().getFullYear();
                    getmonthData(currentMonth, currentYear);
                    setDashboard(response.Dashboard);
                    const currentMonthIndex = months.findIndex(
                        (item) => item.value === currentMonth
                    );

                    getmo(currentMonthIndex + 1, currentYear);
                }
                if (response.Status === 0) {
                    if (response.Message === "Invalid Token") {
                        setSessionExpired(true);
                        return;
                    }
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                setSpinner(false);
                toast.error(
                    `something went Wrong please try again after sometime`,
                    {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    }
                );
            });
        //eslint-disable-next-line
    }, [token]);
    const monthChangeHandler = (e) => {
        let value = String(e.target.value).split(" ");

        getmonthData(value[0], value[1]);
    };

    return (
        <>
            {sessionExpired && <Modal />}
            <div className="ui">
                <div className="ui-leftside">
                    <div className="navbar">
                        <Navbar active="dashboard" />
                    </div>
                </div>
                <div className="ui-rightside ">
                    <MainTopNav />
                    <div className="ui-wrapper">
                        {spinner ? (
                            <Spinner />
                        ) : (
                            <div className="DashBoardWrapper">
                                <div className="dashboard-header-wrapper">
                                    <h3>Dashboard</h3>
                                    <select
                                        onChange={monthChangeHandler}
                                        defaultValue={currentMonth}>
                                        {allMonths.map((item) => (
                                            <option
                                                value={`${item.longMonth} ${item.year}`}>
                                                {item.month}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="dashboard-items">
                                    <div className="dashboard-item">
                                        <h3>Total Wallet Load</h3>
                                        <p className="count">
                                            {Number(monthData.Inflow).toFixed(
                                                0
                                            )}
                                        </p>
                                    </div>
                                    <div className="dashboard-item">
                                        <h3>Total Payouts</h3>
                                        <p className="count">
                                            {Number(monthData.Outflow).toFixed(
                                                0
                                            )}
                                        </p>
                                    </div>
                                    <div className="dashboard-item">
                                        <h3>Total Wallet Amount</h3>
                                        <p className="count">
                                            {Number(
                                                dashboard.TotalWalletAmount
                                            ).toFixed(0)}
                                        </p>
                                    </div>
                                    <div className="dashboard-item">
                                        <h3>Total Charges</h3>
                                        <p className="count">
                                            {Number(
                                                monthData.totalCharges
                                            ).toFixed(0)}
                                        </p>
                                    </div>
                                    <div className="dashboard-item">
                                        <h3>Total Commission</h3>
                                        <p className="count">
                                            {Number(
                                                monthData.totalCommissions
                                            ).toFixed(0)}
                                        </p>
                                    </div>
                                    <div className="dashboard-item">
                                        <h3>Total Fees</h3>
                                        <p className="count">
                                            {Number(
                                                monthData.totalFees
                                            ).toFixed(0)}
                                        </p>
                                    </div>
                                    <div className="dashboard-item">
                                        <h3>Total Business Users</h3>
                                        <p className="count">
                                            {dashboard.BusinessUsersCount}
                                        </p>
                                    </div>
                                    <div className="dashboard-item">
                                        <h3>Total Retail Users</h3>
                                        <p className="count">
                                            {dashboard.UsersCount}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Dashboard;

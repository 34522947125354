import React, { useEffect, useState } from "react";
import Navbar from "../dashboard/navbar";
import { toast } from "react-toastify";
import Spinner from "../Spinner";
import TopNav from "./topNav";
import { useNavigate, useParams } from "react-router-dom";
import MainTopNav from "../maintopnav";
import { IoMdArrowRoundBack } from "react-icons/io";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import Modal from "../401";
function Dashboard() {
    const isKYCverified = sessionStorage.getItem("isKYCverified");

    const [sessionExpired, setSessionExpired] = useState(false);
    const { id } = useParams();
    const Navigate = useNavigate();
    const token = JSON.parse(sessionStorage.getItem("token"));
    // function numberConversion(val) {
    //     if (val >= 10000000) {
    //         val = (val / 10000000).toFixed(2) + " Cr";
    //     } else if (val >= 100000) {
    //         val = (val / 100000).toFixed(2) + " Lac";
    //     }
    //     /*else if(val >= 1000) val = (val/1000).toFixed(2) + ' K';*/
    //     return val;
    // }
    const [spinner, setSpinner] = useState(true);
    const [dashboard, setDashboard] = useState([]);
    const [firstName, setFirstName] = useState();
    const [currentMonth, setCurrentMonth] = useState({});

    useEffect(() => {
        setFirstName(sessionStorage.getItem("firstname"));
        fetch(`${process.env.REACT_APP_BASE_URL}/dashboard/${id}/admin`, {
            headers: {
                "content-type": "application/json;charset =UTF-8",
                Authorization: `token ${token}`,
            },
        })
            .then((Response) => Response.json())
            .then((response) => {
                console.log(response);
                if (response.Status === 1) {
                    setDashboard(response.Dashboard.MonthlyTransactions);
                    setCurrentMonth(response.Dashboard.MonthlyTransactions[0]);
                }
                if (response.Status === 0) {
                    if (response.Message === "Invalid Token") {
                        setSessionExpired(true);
                        return;
                    }
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
                setSpinner(false);
            })
            .catch((err) => {
                setSpinner(false);
                toast.error(
                    `something went Wrong please try again after sometime`,
                    {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    }
                );
            });
    }, [token, id]);
    const backHandler = () => {
        Navigate("/userlist");
    };
    const monthChange = (e) => {
        const selectedMonth = e.target.value;
        const selectedData = dashboard.filter(
            (data) => data.Month === selectedMonth
        );
        setCurrentMonth(selectedData[0]);
    };
    return (
        <>
            {sessionExpired && <Modal />}
            <div className="ui">
                <div className="ui-leftside">
                    <div className="navbar">
                        <Navbar active="Userlist" />
                    </div>
                </div>
                <div className="ui-rightside ">
                    <MainTopNav />
                    <div className="ui-wrapper">
                        <div className="User-name">
                            <button
                                className="back-button"
                                onClick={backHandler}>
                                <IoMdArrowRoundBack />
                                <p>Back</p>
                            </button>
                            <p
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "0.5rem",
                                }}>
                                {firstName}{" "}
                                {isKYCverified === "1" && (
                                    <CheckCircleIcon
                                        style={{ color: "green" }}
                                    />
                                )}
                            </p>
                        </div>
                        <div className="topNav">
                            <TopNav active="Dashboard" />
                        </div>
                        {spinner ? (
                            <Spinner />
                        ) : (
                            <div className="DashBoardWrapper">
                                <div className="dashboard-header-wrapper">
                                    <h3>Dashboard</h3>
                                    <select
                                        onChange={monthChange}
                                        defaultValue={String(
                                            dashboard[0].Month
                                        )}>
                                        {dashboard.map((data) => (
                                            <option value={data.Month}>
                                                {data.Month}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="dashboard-items">
                                    <div className="dashboard-item">
                                        <h3>Wallet Load</h3>

                                        <p className="count">
                                            {currentMonth.WalletLoad}
                                        </p>
                                    </div>
                                    <div className="dashboard-item">
                                        <h3>Total Payout</h3>
                                        <p className="count">
                                            {currentMonth.Payouts}
                                        </p>
                                    </div>
                                    <div className="dashboard-item">
                                        <h3>Total Amount</h3>
                                        <p className="count">
                                            {currentMonth.AmountTotal}
                                        </p>
                                    </div>
                                    <div className="dashboard-item">
                                        <h3>Payout Count</h3>

                                        <p className="count">
                                            {currentMonth.PayoutsCount}
                                        </p>
                                    </div>
                                    <div className="dashboard-item">
                                        <h3>Total Charges</h3>
                                        <p className="count">
                                            {Number(
                                                currentMonth.TotalCharges
                                            ).toFixed(2)}
                                        </p>
                                    </div>
                                    <div className="dashboard-item">
                                        <h3>Total Fees</h3>
                                        <p className="count">
                                            {Number(
                                                currentMonth.TotalFees
                                            ).toFixed(2)}
                                        </p>
                                    </div>
                                    <div className="dashboard-item">
                                        <h3>Total Commission</h3>
                                        <p className="count">
                                            {Number(
                                                currentMonth.TotalCommissions
                                            ).toFixed(2)}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Dashboard;

import React, { useEffect, useState } from "react";
import Navbar from "../dashboard/navbar";
import { toast } from "react-toastify";
import Spinner from "../Spinner";
import TopNav from "./topNav";
import { useNavigate, useParams } from "react-router-dom";
import MainTopNav from "../maintopnav";
import { IoMdArrowRoundBack } from "react-icons/io";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import Modal from "../401";
function Referral() {
    const { id } = useParams();
    const Navigate = useNavigate();
    const token = JSON.parse(sessionStorage.getItem("token"));
    const [spinner, setSpinner] = useState(true);
    const [referredUsers, setReferredUsers] = useState([]);
    const [firstName, setFirstName] = useState();
    const isKYCverified = sessionStorage.getItem("isKYCverified");

    const [sessionExpired, setSessionExpired] = useState(false);
    useEffect(() => {
        setFirstName(sessionStorage.getItem("firstname"));
        fetch(
            `${process.env.REACT_APP_BASE_URL}/admin/${id}/referred-users?offset=0`,
            {
                headers: {
                    "content-type": "application/json;charset =UTF-8",
                    Authorization: `token ${token}`,
                },
            }
        )
            .then((Response) => Response.json())
            .then((response) => {
                console.log(response);
                if (response.Status === 1) {
                    setReferredUsers(response.ReferredUsers);
                }
                if (response.Status === 0) {
                    if (response.Message === "Invalid Token") {
                        setSessionExpired(true);
                        return;
                    }
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
                setSpinner(false);
            })
            .catch((err) => {
                setSpinner(false);
                toast.error(
                    `something went Wrong please try again after sometime`,
                    {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    }
                );
            });
    }, [token]);
    const backHandler = () => {
        Navigate("/userlist");
    };
    return (
        <>
            {sessionExpired && <Modal />}
            <div className="ui">
                <div className="ui-leftside">
                    <div className="navbar">
                        <Navbar active="Userlist" />
                    </div>
                </div>
                <div className="ui-rightside ">
                    <MainTopNav />
                    <div className="ui-wrapper">
                        <div className="User-name">
                            <button
                                className="back-button"
                                onClick={backHandler}>
                                <IoMdArrowRoundBack />
                                <p>Back</p>
                            </button>{" "}
                            <p
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "0.5rem",
                                }}>
                                {firstName}{" "}
                                {isKYCverified === "1" && (
                                    <CheckCircleIcon
                                        style={{ color: "green" }}
                                    />
                                )}
                            </p>
                        </div>
                        <div className="topNav">
                            <TopNav active="Referral" />
                        </div>
                        <div className="Table" style={{ overflowX: "auto" }}>
                            <h3>Referal Users</h3>
                            {spinner ? (
                                <Spinner />
                            ) : (
                                <table>
                                    <thead>
                                        <tr>
                                            <td>Name</td>
                                            <td>Company Name</td>
                                            <td>Mobile Number</td>
                                            <td>Referal Code</td>
                                            <td>Commission Percentage</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {referredUsers?.length > 0 ? (
                                            referredUsers.map((item) => (
                                                <tr className="referral-row">
                                                    <td>{item.firstName}</td>
                                                    <td>
                                                        {item.companyName
                                                            .length > 0
                                                            ? item.companyName
                                                            : "-"}
                                                    </td>
                                                    <td>{item.mobileNumber}</td>
                                                    <td>
                                                        {item.referredByCode}
                                                    </td>
                                                    <td>
                                                        {
                                                            item.walletCommissionPercentage
                                                        }
                                                        %
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td
                                                    colSpan={5}
                                                    style={{
                                                        fontWeight: 500,
                                                        textAlign: "center",
                                                    }}>
                                                    No Referal Found
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Referral;

import React, { useEffect, useState } from "react";
import Navbar from "../dashboard/navbar";
import { toast } from "react-toastify";
import TopNav from "./topNav";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../Spinner";
import MainTopNav from "../maintopnav";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { IoMdArrowRoundBack } from "react-icons/io";

import Modal from "../401";
function Settings() {
    const token = JSON.parse(sessionStorage.getItem("token"));
    const [isUPIPayoutsEnabled, setisUPIPayoutsEnabled] = useState();
    const [sessionExpired, setSessionExpired] = useState(false);
    const { id } = useParams();
    const [spinner, setSpinner] = useState(true);
    const [
        walletCommissionPercentage_pinelabs,
        setwalletCommissionPercentage_pinelabs,
    ] = useState();
    const [details, setDetails] = useState([]);
    const Navigate = useNavigate();
    const [status, setstatus] = useState();
    const [payOutBlock, setPayOutBlock] = useState();
    const [walletblocked, setwalletblocked] = useState();
    const [walletCommission, setwalletCommision] = useState(0);
    const [payoutType, setPayOutTtype] = useState();
    const [payoutTypeAmount, setPayOutAmount] = useState();
    const [allowComission, setAllowCommision] = useState();
    const [monthlyCommitment, setMonthlyCommitment] = useState();
    const [firstName, setFirstName] = useState();
    const [error, seterror] = useState(null);
    const [commissionupdate, setcommissionupdate] = useState();
    const isKYCverified = sessionStorage.getItem("isKYCverified");
    const [notes, setnotes] = useState();
    const [hold, sethold] = useState();
    const statuschange = (e) => {
        setstatus(e.target.value);
    };
    const payoutblockChange = (e) => {
        setPayOutBlock(e.target.value);
    };
    const noteschange = (e) => {
        setnotes(e.target.value);
    };
    const referralUsersCommissionEditStopChange = (e) => {
        setcommissionupdate(e.target.value);
    };
    const walletblockchange = (e) => {
        setwalletblocked(e.target.value);
    };
    const payouttypeChange = (e) => {
        setPayOutTtype(e.target.value);
    };
    const commissionChange = (e) => {
        setAllowCommision(e.target.value);
    };
    const UPIPayoutschange = (e) => {
        setisUPIPayoutsEnabled(e.target.value);
    };
    useEffect(() => {
        setFirstName(sessionStorage.getItem("firstname"));
        fetch(`${process.env.REACT_APP_BASE_URL}/admin/users?userId=${id}`, {
            headers: {
                "content-type": "application/json;charset =UTF-8",
                Authorization: `token ${token}`,
            },
        })
            .then((Response) => Response.json())
            .then((response) => {
                console.log(response);
                if (response.Status === 1) {
                    setDetails(response.UserDetails);
                    setwalletCommision(
                        response.UserDetails.walletCommissionPercentage
                    );
                    setwalletCommissionPercentage_pinelabs(
                        response.UserDetails.walletCommissionPercentage_pinelabs
                    );
                    setPayOutAmount(response.UserDetails.payoutChargeAmount);
                    setMonthlyCommitment(
                        response.UserDetails.monthlyBusinessCommitment
                    );
                    setisUPIPayoutsEnabled(
                        response.UserDetails.isUPIPayoutsEnabled
                    );
                    sethold(response.UserDetails.holdAmount);
                    setstatus(response.UserDetails.isBlocked);
                    setPayOutBlock(response.UserDetails.isPayoutBlocked);
                    setwalletblocked(response.UserDetails.isDepositBlocked);
                    setPayOutTtype(
                        String(response.UserDetails.payoutChargeType)
                    );
                    setnotes(response.UserDetails.adminNotes);
                    setAllowCommision(
                        response.UserDetails.isReferralCommissionAllowed
                    );
                    setcommissionupdate(
                        response.UserDetails.referralUsersCommissionEditStop
                    );
                }
                if (response.Status === 0) {
                    if (response.Message === "Invalid Token") {
                        setSessionExpired(true);
                        return;
                    }
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
                setSpinner(false);
            })
            .catch((err) => {
                setSpinner(false);
                toast.error(
                    `something went Wrong please try again after sometime`,
                    {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    }
                );
            });
    }, [token, id]);
    const monthlychange = (e) => {
        setMonthlyCommitment(e.target.value);
    };
    const holdchange = (e) => {
        sethold(e.target.value);
    };
    const walletchange = (e) => {
        setwalletCommision(e.target.value);
    };
    const walletchangepg2 = (e) =>
        setwalletCommissionPercentage_pinelabs(e.target.value);

    const payoutchargeChange = (e) => {
        setPayOutAmount(e.target.value);
    };
    const submitHandler = (e) => {
        e.preventDefault();
        let amount;
        if (payoutType === "2") {
            amount = { payoutChargePercentage: payoutTypeAmount };
        }
        if (payoutType === "3") {
            amount = { payoutChargeAmount: payoutTypeAmount };
        }
        if (walletCommission < 1.15) {
            seterror(
                "wallet commission percentage should be greater than 1.15"
            );
            return;
        }
        if (walletCommissionPercentage_pinelabs < 1.15) {
            seterror(
                "wallet commission percentage should be greater than 1.15"
            );
            return;
        }
        seterror(null);

        fetch(
            `${process.env.REACT_APP_BASE_URL}/admin/${id}/update-userdetails`,
            {
                method: "PUT",
                body: JSON.stringify({
                    walletCommissionPercentage: walletCommission,
                    payoutChargeType: payoutType,
                    ...amount,
                    payoutChargePercentage: payoutTypeAmount,
                    isPayoutBlocked: payOutBlock,
                    isBlocked: status,
                    isReferralCommissionAllowed: allowComission,
                    isDepositBlocked: walletblocked,
                    walletCommissionPercentage_pinelabs,
                    monthlyBusinessCommitment: monthlyCommitment,
                    holdAmount: hold,
                    isUPIPayoutsEnabled: isUPIPayoutsEnabled,
                    referralUsersCommissionEditStop: commissionupdate,
                    adminNotes: notes,
                }),
                headers: {
                    "content-type": "application/json;charset =UTF-8",
                    Authorization: `token ${token}`,
                },
            }
        )
            .then((Response) => Response.json())
            .then((response) => {
                if (response.Status === 1) {
                    toast.success(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
                if (response.Status === 0) {
                    if (response.Message === "Invalid Token") {
                        setSessionExpired(true);
                        return;
                    }
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
                setSpinner(false);
            })
            .catch((err) => {
                setSpinner(false);
                toast.error(
                    `something went Wrong please try again after sometime`,
                    {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    }
                );
            });
    };
    const backHandler = () => {
        Navigate("/BusinessUsers");
    };
    return (
        <>
            {sessionExpired && <Modal />}

            <div className="ui">
                <div className="ui-leftside">
                    <div className="navbar">
                        <Navbar active="BusinessUsers" />
                    </div>
                </div>
                <div className="ui-rightside ">
                    <MainTopNav />
                    <div className="ui-wrapper">
                        <div className="User-name">
                            <button
                                className="back-button"
                                onClick={backHandler}>
                                <IoMdArrowRoundBack />
                                <p>Back</p>
                            </button>

                            <p
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "0.5rem",
                                }}>
                                {firstName}{" "}
                                {isKYCverified === "1" && (
                                    <CheckCircleIcon
                                        style={{ color: "green" }}
                                    />
                                )}
                            </p>
                        </div>
                        <div className="topNav">
                            <TopNav active="Settings" />
                        </div>
                        {spinner ? (
                            <Spinner />
                        ) : (
                            <form className="settings" onSubmit={submitHandler}>
                                <div className="settting-detail">
                                    <label htmlFor="blockuser">
                                        Block User
                                    </label>
                                    <div className="radio-button-wrapper">
                                        <div className="radio-buttons">
                                            <input
                                                type={"radio"}
                                                name="blockuser"
                                                value={1}
                                                onChange={statuschange}
                                                defaultChecked={
                                                    details.isBlocked === 1
                                                }
                                            />
                                            <label htmlFor="yes">Yes</label>
                                        </div>
                                        <div className="radio-buttons">
                                            <input
                                                type={"radio"}
                                                onChange={statuschange}
                                                name="blockuser"
                                                value={0}
                                                defaultChecked={
                                                    details.isBlocked === 0
                                                }
                                            />
                                            <label htmlFor="No">No</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="settting-detail">
                                    <label htmlFor="blockuser">
                                        Block Payouts
                                    </label>
                                    <div className="radio-button-wrapper">
                                        <div className="radio-buttons">
                                            <input
                                                type={"radio"}
                                                onChange={payoutblockChange}
                                                name="BlockPayouts"
                                                value={1}
                                                defaultChecked={
                                                    details.isPayoutBlocked ===
                                                    1
                                                }
                                            />
                                            <label htmlFor="yes">Yes</label>
                                        </div>
                                        <div className="radio-buttons">
                                            <input
                                                onChange={payoutblockChange}
                                                type={"radio"}
                                                name="BlockPayouts"
                                                value={0}
                                                defaultChecked={
                                                    details.isPayoutBlocked ===
                                                    0
                                                }
                                            />
                                            <label htmlFor="No">No</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="settting-detail">
                                    <label htmlFor="blockuser">
                                        Block Wallet Load
                                    </label>
                                    <div className="radio-button-wrapper">
                                        <div className="radio-buttons">
                                            <input
                                                type={"radio"}
                                                onChange={walletblockchange}
                                                name="BlockWalletLoad"
                                                value={1}
                                                defaultChecked={
                                                    details.isDepositBlocked ===
                                                    1
                                                }
                                            />
                                            <label htmlFor="yes">Yes</label>
                                        </div>
                                        <div className="radio-buttons">
                                            <input
                                                type={"radio"}
                                                onChange={walletblockchange}
                                                name="BlockWalletLoad"
                                                value={0}
                                                defaultChecked={
                                                    details.isDepositBlocked ===
                                                    0
                                                }
                                            />
                                            <label htmlFor="No">No</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="settting-detail">
                                    <label htmlFor="blockuser">
                                        UPI Payouts Enabled
                                    </label>
                                    <div className="radio-button-wrapper">
                                        <div className="radio-buttons">
                                            <input
                                                type={"radio"}
                                                onChange={UPIPayoutschange}
                                                name="isUPIPayoutsEnabled"
                                                value={1}
                                                defaultChecked={
                                                    details.isUPIPayoutsEnabled ===
                                                    1
                                                }
                                            />
                                            <label htmlFor="yes">Yes</label>
                                        </div>
                                        <div className="radio-buttons">
                                            <input
                                                type={"radio"}
                                                onChange={UPIPayoutschange}
                                                name="isUPIPayoutsEnabled"
                                                value={0}
                                                defaultChecked={
                                                    details.isUPIPayoutsEnabled ===
                                                    0
                                                }
                                            />
                                            <label htmlFor="No">No</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="settting-detail">
                                    <label htmlFor="blockuser">
                                        Allow Referral user commission update
                                    </label>
                                    <div className="radio-button-wrapper">
                                        <div className="radio-buttons">
                                            <input
                                                type={"radio"}
                                                onChange={
                                                    referralUsersCommissionEditStopChange
                                                }
                                                name="referralUsersCommissionEditStop"
                                                value={1}
                                                defaultChecked={
                                                    details.referralUsersCommissionEditStop ===
                                                    1
                                                }
                                            />
                                            <label htmlFor="yes">Yes</label>
                                        </div>
                                        <div className="radio-buttons">
                                            <input
                                                type={"radio"}
                                                onChange={
                                                    referralUsersCommissionEditStopChange
                                                }
                                                name="referralUsersCommissionEditStop"
                                                value={0}
                                                defaultChecked={
                                                    details.referralUsersCommissionEditStop ===
                                                    0
                                                }
                                            />
                                            <label htmlFor="No">No</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="settting-detail">
                                    <label htmlFor="walletCommission">
                                        Wallet Commission RazorPay(%)
                                    </label>
                                    <input
                                        type={"number"}
                                        onChange={walletchange}
                                        value={walletCommission}
                                    />
                                </div>
                                <div className="settting-detail">
                                    <label htmlFor="walletCommission">
                                        Wallet Commission Pinelabs(%)
                                    </label>
                                    <input
                                        type={"number"}
                                        onChange={walletchangepg2}
                                        value={
                                            walletCommissionPercentage_pinelabs
                                        }
                                    />
                                </div>
                                <div className="settting-detail">
                                    <label htmlFor="blockuser">
                                        Payout Charge Type
                                    </label>
                                    <div className="radio-button-wrapper payoutcharges">
                                        <div className="radio-buttons">
                                            <input
                                                type={"radio"}
                                                onChange={payouttypeChange}
                                                name="chargetype"
                                                value={3}
                                                defaultChecked={
                                                    details.payoutChargeType ===
                                                    3
                                                }
                                            />
                                            <label htmlFor="yes">Fixed</label>
                                        </div>
                                        <div className="radio-buttons">
                                            <input
                                                type={"radio"}
                                                onChange={payouttypeChange}
                                                name="chargetype"
                                                value={2}
                                                defaultChecked={
                                                    details.payoutChargeType ===
                                                    2
                                                }
                                            />
                                            <label htmlFor="No">
                                                percentage
                                            </label>
                                        </div>
                                        <div className="radio-buttons">
                                            <input
                                                type={"radio"}
                                                name="chargetype"
                                                onChange={payouttypeChange}
                                                value={1}
                                                defaultChecked={
                                                    details.payoutChargeType ===
                                                    1
                                                }
                                            />
                                            <label htmlFor="No">None</label>
                                        </div>
                                        {payoutType !== "1" && (
                                            <input
                                                type={"number"}
                                                onChange={payoutchargeChange}
                                                value={payoutTypeAmount}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="settting-detail">
                                    <label htmlFor="blockuser">
                                        Allow Commission
                                    </label>
                                    <div className="radio-button-wrapper">
                                        <div className="radio-buttons">
                                            <input
                                                type={"radio"}
                                                name="Commission"
                                                value={1}
                                                onChange={commissionChange}
                                                defaultChecked={
                                                    details.isReferralCommissionAllowed ===
                                                    1
                                                }
                                            />
                                            <label htmlFor="yes">Yes</label>
                                        </div>
                                        <div className="radio-buttons">
                                            <input
                                                type={"radio"}
                                                name="Commission"
                                                onChange={commissionChange}
                                                value={0}
                                                defaultChecked={
                                                    details.isReferralCommissionAllowed ===
                                                    0
                                                }
                                            />
                                            <label htmlFor="No">No</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="settting-detail">
                                    <label htmlFor="walletCommission">
                                        Monthly Commitment
                                    </label>
                                    <input
                                        type={"number"}
                                        value={monthlyCommitment}
                                        onChange={monthlychange}
                                    />
                                </div>
                                <div className="settting-detail">
                                    <label htmlFor="walletCommission">
                                        Hold Amount
                                    </label>
                                    <input
                                        type={"number"}
                                        value={hold}
                                        onChange={holdchange}
                                    />
                                </div>
                                <div className="settting-detail">
                                    <label htmlFor="walletCommission">
                                        adminNotes
                                    </label>
                                    <input
                                        type={"text"}
                                        value={notes}
                                        onChange={noteschange}
                                    />
                                </div>
                                {error && (
                                    <span style={{ color: "red" }}>
                                        {error}
                                    </span>
                                )}
                                <button type="submint">Save</button>
                            </form>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Settings;

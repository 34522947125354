import React from "react";
import "../Components/businessUsers/modal.css";
import { useNavigate } from "react-router-dom";
function Modal(props) {
    const Navigate = useNavigate();
    const CancelHandler = (e) => {
        e.stopPropagation();
    };
    const approvehandler = (e) => {
        e.stopPropagation();
        Navigate("/");
    };
    const openhandler = (e) => {
        e.stopPropagation();
    };
    return (
        <div className="modal-confirm" onClick={CancelHandler}>
            <div className="modal-content-confirm" onClick={openhandler}>
                <button className="close-btn"></button>
                <p>Session expired please Login</p>
                <div className="control-btns">
                    <button className="delete-btn" onClick={approvehandler}>
                        Ok
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Modal;
